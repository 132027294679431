import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NineGoldLibModule } from 'projects/nine-gold-lib/src/lib/nine-gold-lib.module';
// import { GeneratorComponent } from './application/generator/generator-page/generator.component';
// import { DownloadsComponent } from './application/downloads/downloads-page/downloads.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgSelectModule } from '@ng-select/ng-select';
import { NotificationModule } from 'projects/nine-gold-lib/src/lib/shared/notification/notification.module';
import { AppconfigModule } from 'projects/nine-gold-lib/src/lib/services/appconfig/appconfig.module';
// import { AppconfigService } from 'projects/nine-gold-lib/src/lib/services/appconfig/appconfig.service';
// import { InlineEditComponent } from './directives/inline-edit/inline-edit.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './pages/home/home.component';
import { AnimateModule } from '@wizdm/animate';
import { QuoteFormComponent } from './pages/quote-form/quote-form.component';
// import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
// import { DownloadsSidebarComponent } from './application/downloads/downloads-sidebar/downloads-sidebar.component';
// import { GeneratorSidebarComponent } from './application/generator/generator-sidebar/generator-sidebar.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { GeneratorSettingsService } from './services/generator-settings.service';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { DemoFormComponent } from './pages/demo-form/demo-form.component';
import { SigninRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signout-redirect-callback.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptorService } from 'projects/nine-gold-lib/src/lib/services/api-interceptor.service';
import { DatePipe } from '@angular/common';
import { SupportRequestComponent } from './pages/support-request/support-request.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { StatusBarModule } from 'projects/nine-gold-lib/src/lib/shared/status-bar/status-bar.module';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { ProgressBarModule } from 'projects/rest-generator-app/src/app/application/progress-bar/progress-bar.module';


@NgModule({
  declarations: [
    AppComponent,
    // GeneratorComponent,
    // DownloadsComponent,
    // InlineEditComponent,
    HomeComponent,
    QuoteFormComponent,
    // AppLayoutComponent,
    // DownloadsSidebarComponent,
    // GeneratorSidebarComponent,
    PricingComponent,
    DemoFormComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    SupportRequestComponent,
    DocumentationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NineGoldLibModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    NgSelectModule,
    FontAwesomeModule,
    NotificationModule,
    StatusBarModule,
    AppconfigModule,
    AnimateModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    ProgressBarModule
  ],
  providers: [DatePipe,{ provide: AppSettingsService, useClass: GeneratorSettingsService },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
