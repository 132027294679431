import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ButtonTypes, ColumnFilter, columnFilterTypes, ColumnTypes, NgListButton, NgListColumns, NgListDataCell, NgListDataRow, NgParentColumns } from './ng-list-data';
import { trigger, transition, style, animate, query, stagger, state, group } from '@angular/animations';
import { faArrowUp, faBullseye, faCheck, faChevronDown, faCirclePlus, faClock, faCode, faCog, faDollarSign, faDownload, faEllipsisH, faEllipsisV, faExpandArrowsAlt, faFilter, faGripLines, faHandHoldingUsd, faIdBadge, faInfo, faInfoCircle, faLaptop, faLaptopCode, faMasksTheater, faQuestionCircle, faRedoAlt, faRotateRight, faShareSquare, faSortUp, faSyncAlt, faTrash, faUser, faUserSecret, faUserSlash, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { Icon } from '@fortawesome/fontawesome-svg-core';
// import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
// import { stringify } from 'querystring';
import { BsDropdownConfig, BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ListsService } from '../../../services/lists.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Density } from '../../../models/ui-settings';
import { UiSettingsService } from '../../../services/ui-settings.service';
import { Subscription } from 'rxjs';
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
// import { faClock } from '@fortawesome/free-regular-svg-icons';

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0, transform: 'scale(.95)' }), stagger('80ms', animate('500ms ease-out', style({ opacity: 1, transform: 'scale(1)' })))],
      { optional: true }
    ),
  ])
]);

const accordionAnimation = trigger('accordionAnimation', [
  state('open', style({
    overflow: 'visible',
    height: '*',
    opacity: '1'
  })),
  state('closed', style({
    overflow: 'hidden',
    height: '0px',
    opacity: '0'
  })),
  transition('open => closed', [
    style({height: '*',opacity: '1', transform: 'scale(1)'}),
    group([
      animate('0.25s ease-in', style({opacity: '0', transform: 'scale(.95)'})),
      animate('.35s .25s ease-in-out', style({height: '0px'}))
    ])
  ]),

  transition('closed => open', [
    style({height: '0px', opacity: '0', transform: 'scale(.95)'}),
    group([
      animate('.25s .35s ease-out', style({opacity: '1', transform: 'scale(1)'})),
      animate('.35s ease-out', style({height: '*'}))
    ])
  ])
]);

@Component({
  selector: 'lib-ng-list',
  templateUrl: './ng-list.component.html',
  styleUrls: ['./ng-list.component.scss'],
  animations: [listAnimation, accordionAnimation],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: true, insideClick: true } }]
})
export class NgListComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  initialLoad: boolean;

  noResults = false;

  rowStyle: string; //! check and/or remove

  @ViewChild('tableContainer') tableContainerElement: ElementRef;
  @ViewChild('downloadsTable') tableElement: ElementRef;
  @ViewChild('tableSettings') tableSettings: ElementRef;
  @ViewChild('tableSettingsButton') tableSettingsButton: ElementRef;
  @ViewChild('headerRow') headerRow: ElementRef;
  @ViewChild('filtersContainer') filtersContainer: ElementRef;

  // table menu popup
  showTableMenu = false;
  listenerFn: () => void;

  // filters popup
  filtersListenerFn: () => void;

  resizeId: any;
  resizeL;

  @Input() hideHeader = false;  // to check and/or remove
  @Output() buttonClicked: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() accordionAction: EventEmitter<NgListDataRow> = new EventEmitter<NgListDataRow>();
  @Input() listsService: ListsService;
  @Input() filters?: any[];
  @Input() detailsTemplate?: TemplateRef<any>;
  @Input() internalTable = false;
  @Input() expandable = false;
  @Input() firstColExpand = false;
  @Input() expandMultiple = false;
  @Input() tableId = "";
  @Input() noResultsText = "There are no results to show.";
  @Input() refreshButton = false;
  @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() infoColumn = true;
  @Input() moreButtonsColumn = false;

  @Input() subRows = false;

  accordionState = "closed";

  density: Density = Density.default;

  sorting: {field: string, order: string};
  pageParams: {page: number, perPage: number} = {page: 1, perPage: 10};
  totalPages: number;
  columns: NgListColumns[] = [];
  subColumns: NgParentColumns[] = [];
  titleColumn: NgListColumns = null;
  titleColSpan: number = 1;
  data: NgListDataRow[] = [];
  enlargeIcon = faExpandArrowsAlt;

  showHideColumns: NgListColumns[] = [];
  showFilters = false;
  lockFirstCol = false;

  refreshIcon = faSyncAlt;

  iconButtons: {type: string,icon: IconDefinition}[] = [
    {
      type: ButtonTypes.info,
      icon: faInfo
    },
    {
      type: ButtonTypes.delete,
      icon: faTrash
    },{
      type: ButtonTypes.download,
      icon: faDownload
    },{
      type: ButtonTypes.resend,
      icon: faShareSquare
    },
    {
      type: ButtonTypes.resend_invite,
      icon: faRedoAlt
    },
    {
      type: ButtonTypes.revoke,
      icon: faUserTimes
    },
    {
      type: ButtonTypes.upgrade,
      icon: faArrowUp
    },
    {
      type: ButtonTypes.user,
      icon: faUser
    },
    {
      type: ButtonTypes.user_delete,
      icon: faUserTimes
    },
    {
      type: ButtonTypes.user_remove,
      icon: faUserSlash
    },
    {
      type: ButtonTypes.renew,
      icon: faSyncAlt
    },
    {
      type: ButtonTypes.quote,
      icon: faDollarSign
    },
    {
      type: ButtonTypes.trial,
      icon: faClock
    },
    {
      type: ButtonTypes.demo,
      icon: faLaptop
    },
    {
      type: ButtonTypes.impersonate,
      icon: faMasksTheater
    },
    {
      type: ButtonTypes.license_add,
      icon: faIdBadge
    },
    {
      type: ButtonTypes.license_paid,
      icon: faIdBadge
    },
    {
      type: ButtonTypes.license_renew,
      icon: faRotateRight
    },
    {
      type: ButtonTypes.expand,
      icon: faChevronDown
    },
    {
      type: ButtonTypes.cell_download,
      icon: faDownload
    },
    {
      type: ButtonTypes.generate,
      icon: faCode
    },
    {
      type: ButtonTypes.swagger,
      icon: faEllipsisH
    }
  ]

  resizeWindow;
  cellInfoButton: NgListButton = {
    type: ButtonTypes.cell_info,
    value: '',
    buttonText: 'More Info'
  }

  infoButton: NgListButton = {
    type: ButtonTypes.info,
    value: '',
    buttonText: ''
  }

  downloadButton: NgListButton = {
    type: ButtonTypes.cell_download,
    value: '',
    buttonText: 'Download File'
  }

  infoIcon = faInfoCircle;
  sortIcon = faSortUp;
  filterIcon = faFilter;
  checkIcon = faCheck;
  questionCircle = faQuestionCircle;
  dotsIcon = faEllipsisV;
  settinggIcon = faCog;
  gripIcon = faGripLines;
  downloadIcon = faDownload;
  resutlsStarted: Subscription;
  resutlsEnded: Subscription;
  paramsUpdated: Subscription;

  states: string[] = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Dakota',
    'North Carolina',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];

  autocompleteSelected: string;



  constructor(
    private renderer: Renderer2,
    private uiSettingsService: UiSettingsService
    ) {

  }

  ngOnInit(): void {
    this.initialLoad = true;
    this.columns = this.listsService.GetTableColumns();
    this.UpdateSortClasses();
    if(this.columns.some(col => col.subColumnParent)) {
      this.setSubColumns();
      this.setTitleColumn();
      this.titleColSpan = this.calculateColspan();
    }
    this.setSettingColumns();

    // if(this.uiSettingsService.checkIfTableInSettings(this.tableId)) {
    //   let tableUI = this.uiSettingsService.getTableData(this.tableId);
    //   this.showHideColumns.forEach(col => {
    //     let tableEl = tableUI.cols.find(el => el.name = col.columnName);
    //     if(tableEl) {
    //       col.notVisible = !tableEl.show;
    //       col.columnOrder = tableEl.order;
    //     }
    //   });
    // }

    // this.setUiSettings();
    this.paramsUpdated = this.listsService.parametersUpdated.subscribe(
      (parameters) => {
        this.sorting = parameters.sorting;
        this.pageParams = {
          page: parameters.currentPage,
          perPage: parameters.itemsPerPage
        }
        this.totalPages = parameters.numberOfPages
        this.UpdateSortClasses();
      }
    )
    this.resutlsEnded = this.listsService.loadResultsEnded.subscribe(
      (results) => {
        this.initialLoad = false;
        this.RemoveLoadingSpinner();
        this.data = this.listsService.GetTableRows();
        this.noResults = this.data.length === 0;
        this.setUiSettings();
        this.data.forEach(el => el.accordionState = 'closed')
        this.SetAfterHeight();
        this.unlockColumnsWidth();
        setTimeout(() => {
          this.setFiltersContainerPosition();
        }, 100);

      }
    )
    this.resutlsStarted = this.listsService.loadResultsStarted.subscribe(
      (results) => {
        this.lockColumnsWidth();
        this.SetInitialHeight();
        this.data = [];
        this.SetLoadingSpinner();
      }
    );
  }

  ngOnDestroy(): void {
    this.removeResizeListener();
    this.paramsUpdated.unsubscribe();
    this.resutlsStarted.unsubscribe();
    this.resutlsEnded.unsubscribe();
  }

  protected infoButtonInRow(buttons: NgListButton[]): boolean {
    return buttons.some(btn => btn.type === ButtonTypes.info);
  }

  protected moreThanInfo(buttons: NgListButton[]): boolean {
    return buttons.some(btn => btn.type !== ButtonTypes.info);
  }

  protected filteredButtons(buttons: NgListButton[]): NgListButton[] {
    return buttons.filter(btn => btn.type !== ButtonTypes.info);
  }

  // ===========================================================================
  // Functionality from template
  // ===========================================================================

  // ---------------------------------------------------------------------------
  // Table options menu
  // ---------------------------------------------------------------------------

  // Open and close options menu
  toggleTableMenu():void {
    this.showTableMenu = !this.showTableMenu;
    if(this.showTableMenu) {
      setTimeout(() => {
        this.listenerFn = this.renderer.listen('window', 'click',(e:Event)=>{
          /**
           * Only run when toggleButton is not clicked
           * If we don't check this, all clicks (even on the toggle button) gets into this
           * section which in the result we might never see the menu open!
           * And the menu itself is checked here, and it's where we check just outside of
           * the menu and button the condition abbove must close the menu
           */
            if(e.target !== this.tableSettings.nativeElement && e.target !== this.tableSettingsButton.nativeElement
              && !this.tableSettings.nativeElement.contains(e.target)){
                this.showTableMenu = false;
                this.listenerFn();
            }
        });
      }, 100);

    } else {
      this.listenerFn();
    }
  }



  // ---------------------------------------------------------------------------
  // Table within table functionality
  // ---------------------------------------------------------------------------
  // Set list of columns that can show or hide
  // TODO: probably remove sub rows functionality
  setSettingColumns(): void {
    if(this.subRows) {
      this.showHideColumns = this.subColumns.filter(col => col.canShowHide);
    } else {
      this.showHideColumns = this.columns.filter(col => col.canShowHide);
    }
  }

  refresh(): void {
    this.refreshEvent.emit();
  }

  // Setting title column
  setTitleColumn(): void {
    this.titleColumn = this.columns.find(col => col.titleColumn === true);
  }
  // Setting subrows columns
  setSubColumns():void {
    this.subColumns = this.columns.find(col => col.subColumnParent).subColumns;
  }

  // Getting number of subcolumns, needed for title column colpan (how many columns wide it needs to be)
  calculateColspan(): number {
    return this.subColumns.filter(col => !col.notVisible).length;
  }





// -----------------------------------------------------------------------------
// Acccordions
// -----------------------------------------------------------------------------
// TODO might need to remove this functionality
  toggleAllAccordions(): void {
    if (this.anyRowExpanded()) {
      this.data.forEach(row => {
        row.showDetails = false;
        row.accordionState = "close";
      });
    } else {
      this.data.forEach(row => {
        row.showDetails = true;
        row.accordionState = "open";
      });
    }

  }

  // Open/close accordion
  toggleAccordion(event: any, datarow: NgListDataRow):void {
    event.target.blur();
    if(!this.expandMultiple) {
      if(datarow.showDetails) {
        this.closeRow(datarow);
      } else {
        if(this.data.some(el => el.showDetails)) {
          this.closeAllRowAccordions();
          setTimeout(() => {
            datarow.showDetails = true;
            this.accordionAction.emit(datarow);
            setTimeout(() => {
              datarow.accordionState = "open";
            }, 200);
          }, 800);
        } else {
          datarow.showDetails = true;
          this.accordionAction.emit(datarow);
          setTimeout(() => {
            datarow.accordionState = "open";
          }, 500);
        }
      }
    } else {
      if(datarow.showDetails) {
        datarow.accordionState = datarow.showDetails ? "open" : "closed";
        setTimeout(() => {
          datarow.showDetails = !datarow.showDetails;
          this.accordionAction.emit(datarow);
        }, 200);
      } else {
        datarow.showDetails = !datarow.showDetails;
        this.accordionAction.emit(datarow);
        setTimeout(() => {
          datarow.accordionState = datarow.showDetails ? "open" : "closed";
        }, 200);
      }
    }


      // this.SetAfterHeight();

  }



  // TODO - check and remove
  ToggleFiltersVisibility(event: any): void {
    this.showFilters = !this.showFilters;
  }


  // TODO check and remove (another accordion functionality)
  private closeRow(row: NgListDataRow): void {
    row.accordionState = "closed";
    setTimeout(() => {
      row.showDetails = false;
    }, 500);
  }

  // TODO check and remove (accodion functionality)
  private closeAllRowAccordions():void {
    this.data.forEach(el => {
      if(el.showDetails) {
        setTimeout(() => {
          this.closeRow(el);
        }, 100);
      }
    });
  }



  colMouseEnter(cell: NgListDataCell): void {
    let colNumebr = cell.columnOrder;
    if (this.subRows) {
      this.data.forEach(row => {
        row.subList.forEach(subrow => {
          subrow.listRow.find(el => el.columnOrder === colNumebr).colHover = true;
        });

      });
    } else {
      this.data.forEach(row => {
        row.listRow.find(el => el.columnOrder === colNumebr).colHover = true;
      });
    }

  }

  colMouseLeave(cell: NgListDataCell): void {
    let colNumebr = cell.columnOrder;
    if (this.subRows) {
      this.data.forEach(row => {
        row.subList.forEach(subrow => {
          subrow.listRow.find(el => el.columnOrder === colNumebr).colHover = false;
        });

      });
    } else {
      this.data.forEach(row => {
        row.listRow.find(el => el.columnOrder === colNumebr).colHover = false;
      });
    }
    // this.data.forEach(row => {
    //   row.listRow.find(el => el.columnOrder === colNumebr).colHover = false;
    // });
  }

  setRowStyle(): string {
    let style = "grid-auto-columns:";
    this.columns.forEach(col => {
      if(!col.notVisible) {
        if(col.columnClasses.includes('date-column')) {
          style += ' 9em';
        } else if(col.columnClasses.includes('one-button')) {
          style += ' 7em';
        } else if(col.columnClasses.includes('two-buttons')) {
          style += ' 8em';
        } else if(col.columnClasses.includes('three-buttons')) {
          style += ' 9em';
        } else if(col.columnClasses.includes('role-container-column')) {
          style += ' 5.5em';
        } else {
          style += ' 1fr';
        }
      }
    });
    style += ';';
    return style;
  }

  anyRowExpanded(): boolean {
    return this.data.some(row => row.showDetails === true);
  }


  SetInitialHeight(): void {
    // this function is to set height of container before loading new results
    if (this.tableElement || this.tableContainerElement) {
      if(this.initialLoad) {
        this.renderer.setStyle(this.tableContainerElement.nativeElement, "height", "600px");
      } else {
        var height = `${(this.tableElement.nativeElement?.offsetHeight || 600)}px`;
        this.renderer.setStyle(this.tableContainerElement.nativeElement, "height", height);
      }
    }
  }

  trackByColumnName(index: number, el:any):string {
    return el.columnName;
  }

  setPosition(event: any): void {
    let buttonClicked = (event.target.tagName === 'button' ? event.target : event.target.closest('button'));
    let pos = buttonClicked.getBoundingClientRect();
    let ul = buttonClicked.parentNode.getElementsByTagName('ul')[0];
    let dropdown = buttonClicked.parentNode.getElementsByClassName('dropdown-menu')[0];
    if(dropdown) {
      dropdown.style.top = pos.bottom + 'px';
      dropdown.style.right = document.documentElement.clientWidth - pos.right + 'px';
      dropdown.style.position = 'fixed';
    } else {
      setTimeout(() => {
        dropdown = buttonClicked.parentNode.getElementsByClassName('dropdown-menu')[0];
        ul = buttonClicked.parentNode.getElementsByTagName('ul')[0];
        dropdown.style.top = pos.bottom + 'px';
        dropdown.style.right = document.documentElement.clientWidth - pos.right + 'px';
        dropdown.style.position = 'fixed';
      }, 10);
    }


  }



  onOpenChange(event: any, buttonId: string): void {
    if(event) {
      document.getElementById(buttonId).closest('td')?.classList.add('dropdown-open');
      document.getElementById(buttonId).closest('th')?.classList.add('dropdown-open');
    } else {
      setTimeout(() => {
        document.getElementById(buttonId).closest('td')?.classList.remove('dropdown-open');
        document.getElementById(buttonId).closest('th')?.classList.remove('dropdown-open');
      }, 200);

    }
  }

  trackByIdentifier(index: number, element: any): string {
    return element.rowIdentifier;
  }

  private lockColumnsWidth():void {
    let cols:any[] = Array.from(this.headerRow.nativeElement.children);
    cols.forEach(el => {
      var width = `${(el.offsetWidth)}px`;
      this.renderer.setStyle(el, "width", width);
    });
  }

  private unlockColumnsWidth():void {
    let cols:any[] = Array.from(this.headerRow.nativeElement.children);
    cols.forEach(el => {
      this.renderer.removeStyle(el, "width");
    });
  }

  SetAfterHeight(): void {
    setTimeout(() => {
      if (this.tableElement && this.tableContainerElement) {
        var height = `${this.tableElement.nativeElement.offsetHeight}px`;
        this.renderer.setStyle(this.tableContainerElement.nativeElement, "height", height);
        setTimeout(() => {
          this.renderer.setStyle(this.tableContainerElement.nativeElement, "height", 'auto');
        }, 500);
      }
    }, 150);
  }

  SetLoadingSpinner(): void {
    this.loading = true;
  }

  RemoveLoadingSpinner(): void {
    this.loading = false;
  }

  UpdateSortClasses(): void {
    let sortedColumn = this.columns.find(col => col.sortClasses?.length > 0);
    if (sortedColumn) sortedColumn.sortClasses = ""
    let sortKey = this.listsService.GetSorting().field;
    let sortOrder = this.listsService.GetSorting().order;
    this.columns.find(col => col.sortColumnName === sortKey).sortClasses = (sortOrder === "a" ? "sort-up" : "sort-down");
  }

  isText(type: ColumnTypes): boolean {
    return type === ColumnTypes.text;
  }

  isDate(type: ColumnTypes): boolean {
    return type === ColumnTypes.date;
  }

  areButtons(type: ColumnTypes): boolean {
    return type === ColumnTypes.buttons;
  }

  buttonClick(button: NgListButton, cell: NgListDataCell, row: NgListDataRow): void {
    this.buttonClicked.emit({button: button,cell: cell,row: row});
  }

  isIconButton(buttonType: ButtonTypes): boolean {
    return buttonType !== ButtonTypes.none;
  }


  getIconOfButton(buttonType: ButtonTypes): IconDefinition {
    let iconBtn = this.iconButtons.find(el => {return el.type === buttonType});
    return iconBtn.icon;
    return null
    // return iconBtn.icon;
  }

  getColumnClasses(element: Element, column: NgListColumns): string {
    let columnClass = "";
    if(column.columnType === ColumnTypes.buttons) {

    }
    return columnClass;
  }

  sortTable(columnName: string, order = null): void {
    if(order) {
      this.listsService.UpdateSortAndOrder(columnName, order);
    } else {
      this.listsService.UpdateSort(columnName);
    }
  }

  filterSelected(column: NgListColumns, dropdownMenu: BsDropdownDirective): void {
    dropdownMenu?.hide();
    this.closeAllFilterPopups();
    this.listsService.SetFilterFromColumn(column);
  }

  CloseExpanded(event: any):void {
    if (
      !event.target.classList.contains('accordionButton') && !event.target.closest('button')?.classList.contains('accordionButton')
      && !event.target.closest('tr')?.classList.contains('details') && !event.target.closest('tr')?.closest('table')?.closest('tr')?.classList.contains('details')
      && !event.target.closest('tr')?.classList.contains('details-expanded')
      ) {

      let rowExpanded = this.data.find(row => row.showDetails);
      if (rowExpanded) {
        this.closeRow(rowExpanded);
      }
    }
  }

  setFiltersContainerPosition():void {
    let scrollLeft = this.tableElement.nativeElement.scrollLeft;
    this.renderer.setStyle(this.filtersContainer.nativeElement,'width',`calc(100% + ${scrollLeft}px)`);
    this.renderer.setStyle(this.filtersContainer.nativeElement,"transform",`translateX(-${scrollLeft}px)`);
    this.columns.forEach(col => {
      let colEl = this.headerRow.nativeElement.querySelector('#th_'+col.columnName);
      if(colEl) {
        let w = colEl.offsetWidth+"px";
        let filEl = this.filtersContainer.nativeElement.querySelector('#filterContainer_'+col.columnName);
        this.renderer.setStyle(filEl,"width",w);
      }
    });




  }


  @HostListener('window:resize',['$event']) handleResize(this):void {
    clearTimeout(this.resizeId);
      this.resizeId = setTimeout(()=>{
        this.closeAllFilterPopups();
        this.setFiltersContainerPosition();
      },100);
  }




  removeResizeListener():void {
    window.removeEventListener('resize',this.handleResize, true);
  }


  toggleFilterPopup(col: NgListColumns):void {
    if(!col.filters.active) {
      let parent = document.getElementById('filterContainer_'+col.columnName);
      let offsetLeft = parent.getBoundingClientRect().x - this.tableContainerElement.nativeElement.getBoundingClientRect().x;
      if (offsetLeft < 0) {
        this.renderer.setStyle(parent.childNodes[0],'margin-left',`${-offsetLeft}px`);
      } else {
        this.renderer.removeStyle(parent.childNodes[0],'margin-left');
      }
    }
    col.filters.active = !col.filters.active;
    if(col.filters.active) {
      setTimeout(() => {
        this.filtersListenerFn = this.renderer.listen('window','click',(e:Event)=>{
          if(e.target !== document.getElementById('filterButton_'+col.columnName)
            && e.target !== document.getElementById('filterContainer_'+col.columnName)
            && !this.filtersContainer.nativeElement.contains(e.target)
            ) {
            col.filters.active = false;
            this.filtersListenerFn();
          }
        });
        if(col.filters.filterType === columnFilterTypes.text || col.filters.filterType === columnFilterTypes.autocomplete) {
          let el = document.getElementById('filterContainer_'+col.columnName);
          el.getElementsByTagName('input')[0].focus();
          // this.filtersContainer.nativeElement.find('input[type=text').nativeElement.focus();
        }
      }, 100);

    } else {
      this.filtersListenerFn();
    }
  }

  private closeAllFilterPopups(): void {
    this.columns.forEach(col => {
      if(col.filters?.active) {
        col.filters.active = false;
        this.filtersListenerFn();
      }
    });
  }

  onTableScroll(event): void {
    this.setFiltersContainerPosition();
    this.closeAllFilterPopups();
  }


  // ===========================================================================
  // Table UI settings functionality
  // ===========================================================================




  SetDensity(dens: string): void {
    // Set density in UI service this.UIservice.setDensity(tableId, density)
    // in that UI service it will save to storage and to user UI settings on server
    // temporary code below
    this.density = Density[dens];
    this.saveUiSettings();
    // this.density = (<any>this.density)[dens];
  }


  // ---------------------------------------------------------------------------
  // Columns visibility
  // ---------------------------------------------------------------------------
  // Show/hide columns
  ToggleColumnVisibility(event: any, column: NgListColumns): void {
    column.notVisible = (column.notVisible ? false : true);
    if(this.subRows) {
      this.data.forEach(parentRow => {
        parentRow.subList.forEach(childRow => {
          childRow.listRow.filter(col => col.columnName === column.columnName).forEach(c => c.notVisible = column.notVisible);
        });
      });
    } else {
      this.data.forEach(row => {
        row.listRow.filter(col => col.columnName === column.columnName).forEach(c => c.notVisible = column.notVisible);
      });
    }
    this.saveUiSettings();
    this.titleColSpan = this.calculateColspan();
  }


  // ---------------------------------------------------------------------------
  // Drop - cdkDropList directive drop item handler
  //        fired on columns order change
  //        rearanges columnOrder in columns (showHideColumns table) and in data rows
  // ---------------------------------------------------------------------------
  public drop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.showHideColumns, event.previousIndex, event.currentIndex);
      let firstIndex = this.columns.length;
      this.showHideColumns.forEach(el => {
        if(el.columnOrder <= firstIndex) firstIndex = el.columnOrder;
      });
      this.showHideColumns.forEach(el => {
        el.columnOrder = firstIndex;
        this.data.forEach(row =>{
          let dataEl = row.listRow.find(col => col.columnName === el.columnName);
          if(dataEl) {
            dataEl.columnOrder = el.columnOrder;
          }
        });
        firstIndex++;
      });
    }
    this.saveUiSettings();
  }

  private saveUiSettings(): void {
    let tables = this.uiSettingsService.convertColumnsToTable(this.showHideColumns);
    this.uiSettingsService.updateTableFromUI({
      table: this.tableId,
      filters: this.showFilters,
      lockFirst: this.lockFirstCol,
      dens: this.density,
      cols: tables
    })
  }

  //! TODO: probably remove this function
  private updateBodySortOrder(): void {
    let firstIndex = 0;
    this.showHideColumns.forEach(el => {
      el.columnOrder = firstIndex;
      this.data.forEach(row =>{
        let dataEl = row.listRow.find(col => col.columnName === el.columnName);
        if(dataEl) {
          dataEl.columnOrder = el.columnOrder;
        }
      });
      firstIndex++;
    });
  }

  private sortColumnsByOrder(): void {
    this.showHideColumns.sort((a,b) => a.columnOrder - b.columnOrder);
    this.data.forEach(row => {
      // row.listRow.sort((a,b) => a.columnOrder - b.columnOrder);
      row.listRow.forEach(el => {
        let col = this.columns.find(col => col.columnName === el.columnName);
        if(col) {
          el.notVisible = col.notVisible;
          el.columnOrder = col.columnOrder;
        }
      });
    });
  }


  // ---------------------------------------------------------------------------
  // setting UI setting for current table
  // Checks if custom UI settings are set for this tableId
  // If yes, it gets settings from UI service and for each column (visibility and order)
  // TODO: set table Density, filters and first row
  // ---------------------------------------------------------------------------
  private setUiSettings(): void {
    if(this.uiSettingsService.checkIfTableInSettings(this.tableId)) {
      let tableUI = this.uiSettingsService.getTableData(this.tableId);
      this.showHideColumns.forEach(col => {
        let tableEl = tableUI.cols.find(el => el.name === col.columnName);
        if(tableEl) {
          col.notVisible = !tableEl.show;
          col.columnOrder = tableEl.order;
        }
      });
      this.density = tableUI.dens;
      this.showFilters = tableUI.filters;
      this.lockFirstCol = tableUI.lockFirst
      this.sortColumnsByOrder();
    }
  }

}
