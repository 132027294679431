<div class="content-wrapper">
  <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="34.66486mm" height="37.799946mm" viewBox="0 0 34.66486 37.799946" version="1.1" id="svg202" inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)" sodipodi:docname="drawing.svg">
    <defs id="defs196" />
    <metadata id="metadata199"></metadata>
    <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-72.625903,-76.350022)">
        <g id="symbol" transform="matrix(0.26458333,0,0,0.26458333,-90.440296,65.473001)" style="fill:#77a7ff;fill-opacity:1">
            <path class="cls-2" d="M 912.25,389.89 941.76,379 a 63.83,63.83 0 0 0 -3.54,-8 l -27.91,14.46 a 32.71,32.71 0 0 1 1.94,4.43 z" transform="translate(-198.23,-282.89)" id="path159" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 908.9,383 26.53,-16.86 a 62.35,62.35 0 0 0 -3.59,-5.1 l -24.79,19.33 c 0.66,0.85 1.27,1.73 1.85,2.63 z" transform="translate(-198.23,-282.89)" id="path161" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 913,410.32 30.34,8.23 a 65.09,65.09 0 0 0 2.2,-13.41 l -31.39,-1.84 a 33.75,33.75 0 0 1 -1.15,7.02 z" transform="translate(-198.23,-282.89)" id="path163" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 914.16,399.45 31.4,-1.58 A 65.44,65.44 0 0 0 943.62,385 l -30.47,7.81 a 33.48,33.48 0 0 1 1.01,6.64 z" transform="translate(-198.23,-282.89)" id="path165" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 908.26,420.53 25.94,17.77 a 65.84,65.84 0 0 0 7.09,-13.51 L 912,413.43 a 33.75,33.75 0 0 1 -3.74,7.1 z" transform="translate(-198.23,-282.89)" id="path167" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 899.13,429.6 17.34,26.23 A 65.92,65.92 0 0 0 929.8,444 L 906,423.48 a 34.18,34.18 0 0 1 -6.87,6.12 z" transform="translate(-198.23,-282.89)" id="path169" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="M 880.07,435.41 A 34.13,34.13 0 0 1 878,367.22 V 380 l 14.69,-10.44 23.73,-16.86 -20,-14.94 L 878,324 v 11.76 a 65.57,65.57 0 1 0 31,124.4 L 895.16,431.9 a 34.07,34.07 0 0 1 -15.09,3.51 z" transform="translate(-198.23,-282.89)" id="path171" style="fill:#77a7ff;fill-opacity:1" />
        </g>
    </g>
  </svg>

  <article class="page-content">
    <header class="homepage-banner generator-banner" role="banner">
      <div class="banner-inner">
        <h1 class="first" wmAnimate="landing" speed="normal" delay="500ms">REST Generator<span>Smart <strong>Code</strong> Platform</span></h1>
      </div>
    </header>

    <main role="main">
      <section class="headquote-section">
        <div class="section-inner">
          <h2 once="true" speed="fast" wmAnimate="landing" speed="normal" delay="800ms">Multi-language software project generation tool to generate smart projects.  Designed to eliminate technical debt by standardizing code across the organization.</h2>
        </div>
      </section>

      <section class="boxes-section">
        <div class="section-inner">

          <ul class="percetage-boxes" #numbersList>
            <li [class.active]="showListItem[0]">
              <div class="circle">
                <lib-counter #counter1 [text]="'faster'" [percent]="80"></lib-counter>
              </div>
              <div class="desc">
                <h3>Accelerates Delivery</h3>
              </div>
            </li>
            <li [class.active]="showListItem[2]">
              <div class="circle">
                <lib-counter #counter3 [text]="'safer'" [percent]="95"></lib-counter>
              </div>
              <div class="desc">
                <h3>Eliminates Human Error</h3>
              </div>
            </li>
            <li [class.active]="showListItem[1]">
              <div class="circle">
                <lib-counter #counter2 [text]="'owned'" [percent]="100"></lib-counter>
              </div>
              <div class="desc">
                <h3>Promotes Code Ownership</h3>
              </div>
            </li>
          </ul>

        </div>
      </section>

      <section>
        <div class="section-inner">
          <div class="transparent-block style-2-left text-and-button" wmAnimate="fadeInUp" once="true" aos="0.8">
            <h4>With REST Generator you can accelerate your delivery by automating your code, giving you a ROI that starts at 450% and grows exponentially with your development needs.</h4>
            <button (click)="RegisterWithTrial()" class="primary">Start FREE Trial</button>
          </div>
        </div>
      </section>

      <section>
        <div class="section-inner">

          <h2 class="no-bottom-margin">Freedom of Choice</h2>

          <div id="apiContractBoxes" class="flex-boxes-container">

            <h3>The market changes every day, regardless of the language, Smart Code allows you the freedom to migrate your integrations with ease.</h3>

            <div class="three-boxes-with-icons four-boxes">
              <section class="support-box box" wmAnimate="landing" speed="normal" once="true" aos="0.5" delay="300ms">
                <a href="https://www.openapis.org" target="_blank" rel="noopener noreferrer">
                  <img src="/assets/images/oas2.png" alt="">
                  <h4>OAS</h4>
                </a>
              </section>

              <section class="support-box box" wmAnimate="landing" speed="normal" once="true" aos="0.5" delay="800ms">
                <a href="https://www.raml.org" target="_blank" rel="noopener noreferrer">
                  <img src="/assets/images/raml.png" alt="RAML icon">
                  <h4>RAML</h4>
                </a>
              </section>

              <section class="support-box box" wmAnimate="landing" speed="normal" once="true" aos="0.5" delay="1200ms">
                <a href="https://www.w3.org/TR/wsdl.html" target="_blank" rel="noopener noreferrer">
                  <img src="/assets/images/wsdl.png" alt="WSDL icon">
                  <h4>WSDL</h4>
                </a>
              </section>

              <section class="support-box box" wmAnimate="landing" speed="normal" once="true" aos="0.5" delay="1200ms">
                <a href="https://www.postman.com/collection/" target="_blank" rel="noopener noreferrer">
                  <img src="/assets/images/postman-icon_white.png" alt="Postman Collection icon">
                  <h4>Postman</h4>
                </a>
              </section>
            </div>

          </div>

          <h2 class="no-bottom-margin">Supported Languages</h2>
          <div>
            <div class="transparent-block narrow flipped-bg style-1-right sub-blocks">
              <div class="sub-block text-and-button even-layout">
                <h4>The easiest way to integrate to your back-end resources.</h4>
                <a role="button" class="secondary no-bg arrow-link dark-container" target="_blank" href="https://www.mulesoft.com">MuleSoft</a>
              </div>
              <div class="horizontal-line"></div>
              <div class="sub-block text-and-button even-layout">
                <h4>The quickest way to develop standalone, production-grade Spring-based APIs.</h4>
                <a role="button" class="secondary no-bg arrow-link dark-container" target="_blank" href="https://spring.io/projects/spring-boot">Spring Boot</a>
              </div>
            </div>

            <!-- <div class="transparent-block narrow flipped-bg style-1-right text-and-button" wmAnimate="fadeInUp" once="true" aos="0.5">
              <h4>The first step in the full lifecycle starts with REST Generator.  Before you start MuleSoft development, click here.</h4>
              <a role="button" class="secondary no-bg arrow-link dark-container" target="_blank" href="https://www.mulesoft.com">MuleSoft</a>
            </div> -->

			      <!-- <div class="transparent-block narrow style-1-left text-and-button" wmAnimate="fadeInUp" once="true" aos="0.5">
				      <h4>Coming next...  The quickest way to develop standalone, production-grade Spring-based APIs.</h4>
              <a role="button" class="secondary no-bg arrow-link dark-container" target="_blank" href="https://spring.io/projects/spring-boot">Spring Boot</a>
            </div> -->

            </div>

        </div>
      </section>

    </main>
  </article>
</div>
