<header class="site-header" role="heading"
    [class.narrow-side-menu]="!uiService.sideMenuWide"
    [class.logged-in]="isLoggedIn"
    [class.min-header]="uiService.minHeader"
    [class.sidebar-open]="uiService.sideMenu && uiService.sideMenuWide"
    [class.sidebar-closed]="uiService.sideMenu && !uiService.sideMenuWide">

    <nav class="site-nav main-nav navbar-nav1 navbar-nav-left">
        <a [routerLink]="'/'" class="logo tm-logo-long">
            <!-- <img class="long" src="/assets/images/NineGoldLogo_noCircle.svg" alt="logo"> -->
            <img class="long" src="/assets/images/smartcode-logo-white-no-circle.svg" alt="logo">

            <img src="/assets/images/circleA.svg" class="spinning" alt="logo">
            <img class="short" src="/assets/images/circleA.svg" alt="logo">
        </a>



        <ul *ngIf="!appView && !uiService.minHeader" [class.open]="mobileNavOpened">
            <li class="drop-down-menu-item">
                <div [class.open]="productMenuOpen" appNgLibHoverPopup class="top-dropdown-menu">
                    <a title="Products List" #productLink>Products<fa-icon [icon]="faCaretDownIcon"></fa-icon></a>
                    <ul #dd class="test dropdown-menu dropdown-menu-center mobile-submenu" role="menu" aria-labelledby="button-animated">
                      <li>
                          <a [href]="products['generator'].link"
                              [title]="products['generator'].altText"
                              [attr.aria-label]="products['generator'].altText"
                              target="_blank"
                              class="dropdown-item outer-link" >REST Generator</a>
                      </li>
                      <li>
                        <a [href]="products['tools'].link"
                            [title]="products['tools'].altText"
                            [attr.aria-label]="products['tools'].altText"
                            target="_blank"
                            class="dropdown-item outer-link" >UTIL Generator</a>
                      </li>

                      <li>
                        <span [title]="'Documents Generator'"
                            [attr.aria-label]="'Documents Generator'"
                            class="dropdown-item no-link" >Documents Generator</span>
                            <span class="mobile-link-description">Coming Soon</span>
                      </li>
                    </ul>
                    <div class="products-dropdown desktop-submenu" #productmenu>

                      <div class="product-dropdown-columns">
                        <div class="product-dropdown-column">
                          <h3>Platform Products</h3>
                          <ul *ngIf="products">
                            <!-- <li *ngIf="products['generator'].outerLink"> -->
                            <li>
                              <a *ngIf="products['generator'].outerLink" target="_blank"
                                [href]="products['generator'].link"
                                [title]="products['generator'].altText"
                                [attr.aria-label]="products['generator'].altText" class="close-dropdown">
                                <fa-icon [icon]="codeIcon"></fa-icon>
                                <div>
                                    <span class="tool-name">{{products['generator'].text}}</span>
                                </div>
                                <span class="tool-description">Maven Project Generator</span>
                                <p>Accelerates API development by automating all the application framework in a standardized format including intergration mapping.</p>
                                <p>Supporting languages:</p>
                                <ul class="inline-list">
                                    <li>MuleSoft</li>
                                    <li>Spring Boot Java</li>
                                </ul>
                              </a>
                              <a *ngIf="!products['generator'].outerLink"
                                [routerLink]="[products['generator'].link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                [title]="products['generator'].altText"
                                [attr.aria-label]="products['generator'].altText" class="close-dropdown">
                                <fa-icon [icon]="codeIcon"></fa-icon>
                                <div>
                                    <span class="tool-name">{{products['generator'].text}}</span>
                                </div>
                                <span class="tool-description">Maven Project Generator</span>
                                <p>Accelerates API development by automating all the application framework in a standardized format including intergration mapping.</p>
                                <p>Supporting languages:</p>
                                <ul class="inline-list">
                                    <li>MuleSoft</li>
                                    <li>Spring Boot Java</li>
                                </ul>
                              </a>
                            </li>

                            <li>
                              <a *ngIf="products['tools'].outerLink" target="_blank"
                                [href]="products['tools'].link"
                                [title]="products['tools'].altText"
                                [attr.aria-label]="products['tools'].altText" class="close-dropdown">
                                <fa-icon [icon]="toolsIcon" class="close-dropdown"></fa-icon>
                                <div>
                                    <span [innerHTML]="products['tools'].text" class="tool-name"></span>
                                </div>
                                <span class="tool-description">Software Development Utilities</span>
                                <p>Assists developers through the development process with schema conversion and test script generators.</p>
                                <ul class="inline-list">
                                  <li>Converters</li>
                                  <li>Generators</li>
                                </ul>
                              </a>
                              <a *ngIf="!products['tools'].outerLink"
                                [routerLink]="[products['tools'].link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                [title]="products['tools'].altText"
                                [attr.aria-label]="products['tools'].altText" class="close-dropdown">
                                <fa-icon [icon]="toolsIcon" class="close-dropdown"></fa-icon>
                                <div>
                                    <span [innerHTML]="products['tools'].text" class="tool-name"></span>
                                </div>
                                <span class="tool-description">Software Development Utilities</span>
                                <p>Assists developers through the development process with schema conversion and test script generators.</p>
                                <ul class="inline-list">
                                  <li>Converters</li>
                                  <li>Generators</li>
                                </ul>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="product-dropdown-column">
                          <h3>Try and Buy</h3>
                          <ul>
                            <li>
                              <a target="_blank" (click)="RegisterTrial()" class="pointer close-dropdown">
                                  <div>
                                      <span class="tool-name">Start a trial</span>
                                  </div>
                                  <span class="tool-description">REST Generator</span>
                                  <p>Access a product with a no cost trial.</p>
                                  <button class="primary" (click)="RegisterTrial()">Try Now</button>
                              </a>
                            </li>
                            <li>
                              <a target="_blank" [routerLink]="[{ outlets: { sidePopup: ['quote','restgen','new']}}]" class="pointer close-dropdown">
                                <div>
                                    <span class="tool-name">Get a quote</span>
                                </div>
                                <span class="tool-description">REST Generator</span>
                                <p>Get a quote for our platform products.</p>
                                <button [routerLink]="[{ outlets: { sidePopup: ['quote','restgen','new']}}]" class="primary">Get Started</button>
                              </a>
                            </li>
                           </ul>
                        </div>

                        <div class="product-dropdown-column coming-soon">
                          <h3>Coming soon</h3>
                          <ul>
                            <li>
                              <div class="product-title no-hover">
                                Documents Generator
                              </div>
                              <span class="tool-description">Technical Documents Generator</span>
                              <p>Automates technical documentation for the integration to a resouce. Provides an interface for Business Analysts to define all the custom mapping required for project generation.</p>
                            </li>
                          </ul>
                        </div>

                      </div>

                    </div>
                </div>
            </li>

            <li class="drop-down-menu-item">
              <div [class.open]="solutionsMenuOpen" appNgLibHoverPopup class="top-dropdown-menu">
                <a title="Solutions Menu" #solutionsLink>Solutions<fa-icon [icon]="faCaretDownIcon"></fa-icon></a>
                <ul #dd class="test dropdown-menu dropdown-menu-center mobile-submenu" role="menu" aria-labelledby="button-animated">
                  <li>
                      <a [href]="solutions['our-strategy'].link"
                          [title]="solutions['our-strategy'].altText"
                          [attr.aria-label]="solutions['our-strategy'].altText"
                          target="_blank"
                          class="dropdown-item outer-link" >Our Strategy</a>
                  </li>
                  <li>
                    <a [href]="solutions['calculating-the-cost'].link"
                        [title]="solutions['calculating-the-cost'].altText"
                        [attr.aria-label]="solutions['calculating-the-cost'].altText"
                        target="_blank"
                        class="dropdown-item outer-link" >Calculating the Costs</a>
                  </li>
                  <li>
                    <a [href]="solutions['migration-risk-assessment'].link"
                        [title]="solutions['migration-risk-assessment'].altText"
                        [attr.aria-label]="solutions['migration-risk-assessment'].altText"
                        target="_blank"
                        class="dropdown-item outer-link" >Migrating Risk Assessment</a>
                  </li>

                </ul>
                <div class="products-dropdown solutions-dropdown desktop-submenu">
                  <div class="product-dropdown-columns">
                    <div class="product-dropdown-column">
                      <h3>iPaaS Migration</h3>
                      <ul>
                        <li>
                          <a *ngIf="solutions['our-strategy'].outerLink" target="_blank"
                            [href]="solutions['our-strategy'].link"
                            [title]="solutions['our-strategy'].altText"
                            class="close-dropdown"
                            [attr.aria-label]="solutions['our-strategy'].altText">
                            <div>
                                <span class="tool-name">{{solutions['our-strategy'].text}}</span>
                            </div>
                            <p>Consolidate your decentralized systems and break the technology silos.</p>
                          </a>
                          <a *ngIf="!solutions['our-strategy'].outerLink"
                            [title]="solutions['our-strategy'].altText"
                            class="close-dropdown"
                            [routerLink]="[solutions['our-strategy'].link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div>
                              <span class="tool-name">{{solutions['our-strategy'].text}}</span>
                            </div>
                            <p>Consolidate your decentralized systems and break the technology silos.</p>
                          </a>
                        </li>
                        <li>
                          <a *ngIf="solutions['calculating-the-cost'].outerLink" target="_blank"
                            [href]="solutions['calculating-the-cost'].link"
                            [title]="solutions['calculating-the-cost'].altText"
                            class="close-dropdown"
                            [attr.aria-label]="solutions['calculating-the-cost'].altText">
                            <div>
                                <span class="tool-name">{{solutions['calculating-the-cost'].text}}</span>
                            </div>
                            <p>Moving to Spring Boot can reduce your total cost of ownership.</p>
                          </a>
                          <a *ngIf="!solutions['calculating-the-cost'].outerLink"
                            [title]="solutions['calculating-the-cost'].altText"
                            [attr.aria-label]="solutions['calculating-the-cost'].altText"
                            class="close-dropdown"
                            [routerLink]="[solutions['calculating-the-cost'].link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div>
                                <span class="tool-name">{{solutions['calculating-the-cost'].text}}</span>
                            </div>
                            <p>Moving to Spring Boot can reduce your total cost of ownership.</p>
                          </a>
                        </li>
                        <li>
                          <a *ngIf="solutions['migration-risk-assessment'].outerLink" target="_blank"
                            [href]="solutions['migration-risk-assessment'].link"
                            [title]="solutions['migration-risk-assessment'].altText"
                            class="close-dropdown"
                            [attr.aria-label]="solutions['migration-risk-assessment'].altText">
                            <div>
                                <span class="tool-name">{{solutions['migration-risk-assessment'].text}}</span>
                            </div>
                            <p>Reducitn your bottom line without operational disruption.</p>
                          </a>
                          <a *ngIf="!solutions['migration-risk-assessment'].outerLink"
                            [title]="solutions['migration-risk-assessment'].altText"
                            [attr.aria-label]="solutions['migration-risk-assessment'].altText"
                            class="close-dropdown"
                            [routerLink]="[solutions['migration-risk-assessment'].link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div>
                                <span class="tool-name">{{solutions['migration-risk-assessment'].text}}</span>
                            </div>
                            <p>Reducitn your bottom line without operational disruption.</p>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="product-dropdown-column">
                      <h3>Services</h3>
                      <ul>
                        <li>
                          <a href="https://calendly.com/diamondedgeit/30min" target="_blank" class="close-dropdown">
                            <div>
                              <span class="tool-name">Architectural Review</span>
                            </div>
                            <p>Review your current state architecture.</p>
                            <span class="lighter text-button external-link">Book a meeting</span>
                          </a>
                        </li>

                        <li>
                          <a href="https://calendly.com/diamondedgeit/30min" target="_blank" class="close-dropdown">
                            <div>
                              <span class="tool-name">Risk Assessment Review</span>
                            </div>
                            <p>Plan your phased approach to migrate to a decentralized achitecture.</p>
                            <span class="lighter text-button external-link">Book a meeting</span>
                          </a>
                        </li>
                      </ul>

                    </div>

                    <div class="product-dropdown-column side-box">
                      <h3>Strategy for Moving to a decentralized architecture</h3>
                      <ul class="bullet-points">
                        <li>Inventory Assessment</li>
                        <li>Criticality Assessment</li>
                        <li>Dependency Mapping</li>
                        <li>Licensing and SLAs</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="drop-down-menu-item">
              <div [class.open]="resourcesMenuOpen" appNgLibHoverPopup class="top-dropdown-menu">
                <a title="Resources Menu" #solutionsLink>Resources<fa-icon [icon]="faCaretDownIcon"></fa-icon></a>

                <ul #dd class="test dropdown-menu dropdown-menu-center mobile-submenu" role="menu" aria-labelledby="button-animated">
                  <li>
                      <a [href]="resources['whitepaper'].link"
                          [title]="resources['whitepaper'].altText"
                          [attr.aria-label]="resources['whitepaper'].altText"
                          target="_blank"
                          class="dropdown-item outer-link" >Avoiding Technical Debt</a>
                  </li>
                  <li>
                    <a [href]="resources['faq'].link"
                        [title]="resources['faq'].altText"
                        [attr.aria-label]="resources['faq'].altText"
                        target="_blank"
                        class="dropdown-item outer-link" >F.A.Q.</a>
                  </li>

                </ul>

                <div class="products-dropdown solutions-dropdown resources-dropdown desktop-submenu">
                  <div class="product-dropdown-columns">
                    <div class="product-dropdown-column">
                      <h3>Whitepapers</h3>
                      <ul>
                        <li>
                          <a *ngIf="resources['whitepaper'].outerLink" target="_blank"
                            [href]="resources['whitepaper'].link"
                            [title]="resources['whitepaper'].altText"
                            class="close-dropdown"
                            [attr.aria-label]="resources['whitepaper'].altText">
                            <div>
                                <span class="tool-name">Avoiding Technical Debt</span>
                            </div>
                            <p>Business operations come with hidden costs. The additional future costs and loss of flexibility a company incurs resulting from poor design, architecture, or code to save time or money is known as technical debt.</p>
                            <span class="lighter text-button external-link">Read the article</span>
                          </a>
                          <a *ngIf="!resources['whitepaper'].outerLink"
                            [title]="resources['whitepaper'].altText"
                            class="close-dropdown"
                            [routerLink]="[resources['whitepaper'].link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div>
                              <span class="tool-name">{{resources['whitepaper'].text}}</span>
                            </div>
                            <p>Business operations come with hidden costs. The additional future costs and loss of flexibility a company incurs resulting from poor design, architecture, or code to save time or money is known as technical debt.</p>
                            <span class="lighter text-button external-link">Read the article</span>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="product-dropdown-column">
                      <h3>Help</h3>
                      <ul>
                        <li class="box-link">
                          <a *ngIf="resources['faq'].outerLink" target="_blank"
                            [href]="resources['faq'].link"
                            [title]="resources['faq'].altText"
                            class="close-dropdown box-link"
                            [attr.aria-label]="resources['faq'].altText">
                            <div>
                                <span class="tool-name">Frequently Asked Questions</span>
                            </div>
                          </a>
                          <a *ngIf="!resources['faq'].outerLink"
                            [title]="resources['faq'].altText"
                            class="close-dropdown box-link"
                            [routerLink]="[resources['faq'].link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div>
                              <span class="tool-name">Frequently Asked Questions</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>



                  </div>
                </div>


              </div>
            </li>

            <li *ngFor="let linkItem of linksList | subnav:undefined | pageLinks:true" [class.open]="productMenuOpen" [class.drop-down-menu-item]="isLinkDropDown(linkItem)">
                <a [title]="linkItem.altText" *ngIf="linkItem.outerLink && !isLinkDropDown(linkItem)" class="outer-link" [href]="linkItem.link" routerLinkActive="active-link">
                    {{linkItem.text}}
                    <!-- <fa-icon [icon]="outerLinkIcon"></fa-icon> -->
                </a>
                <a [title]="linkItem.altText" *ngIf="!linkItem.outerLink  && !isLinkDropDown(linkItem)" [routerLink]="[linkItem.link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{linkItem.text}}</a>
                <!-- <div *ngIf="isLinkDropDown(linkItem)" (mouseenter)="hoverToggle($event)" dropdown class="top-dropdown-menu"> -->
                <!-- <div *ngIf="isLinkDropDown(linkItem)" class="top-dropdown-menu" dropdown> -->
                <div *ngIf="isLinkDropDown(linkItem)" appNgLibHoverPopup class="top-dropdown-menu">
                    <!-- <a [title]="linkItem.altText" dropdownToggle>{{linkItem.text}}<fa-icon [icon]="faCaretDownIcon"></fa-icon></a> -->
                    <a [title]="linkItem.altText">{{linkItem.text}}<fa-icon [icon]="faCaretDownIcon"></fa-icon></a>
                    <!-- <ul *dropdownMenu #ddmenu class="dropdown-menu dropdown-menu-center" role="menu" aria-labelledby="button-animated"> -->
                    <ul #dd class="test dropdown-menu dropdown-menu-center" role="menu" aria-labelledby="button-animated">

                        <li *ngFor="let sublinkItem of filterNotHidden(linksList) | subnav:linkItem.name">
                            <a *ngIf="sublinkItem.outerLink"
                                [title]="sublinkItem.altText"
                                [href]="sublinkItem.link"
                                target="_blank"
                                class="dropdown-item outer-link" [innerHTML]="sublinkItem.text">
                            </a>
                            <a *ngIf="!sublinkItem.outerLink"
                                [title]="sublinkItem.altText"
                                [routerLink]="[sublinkItem.link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                class="dropdown-item outer-link" [innerHTML]="sublinkItem.text">
                            </a>
                        </li>
                    </ul>
                </div>


            </li>
        </ul>
        <ul *ngIf="appView" class="app-main-nav">
            <li *ngFor="let linkItem of linksList | subnav:undefined | appLinks:true" [class.drop-down-menu-item]="isLinkDropDown(linkItem)">
                <a [title]="linkItem.altText" *ngIf="linkItem.outerLink && !isLinkDropDown(linkItem)" [href]="linkItem.link" routerLinkActive="active-link">{{linkItem.text}}</a>
                <a [title]="linkItem.altText" *ngIf="!linkItem.outerLink  && !isLinkDropDown(linkItem)" [routerLink]="[linkItem.link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{linkItem.text}}</a>
                <a [title]="linkItem.altText" *ngIf=" isLinkDropDown(linkItem)">{{linkItem.text}}<fa-icon [icon]="faCaretDownIcon"></fa-icon></a>
                <ul *ngIf=" isLinkDropDown(linkItem)">
                    <li *ngFor="let linksubItem of linksList | subnav:linkItem.name">
                        <a [title]="linksubItem.altText" *ngIf="linksubItem.outerLink" [href]="linksubItem.link" routerLinkActive="active-link">{{linksubItem.text}}</a>
                        <a [title]="linksubItem.altText" *ngIf="!linksubItem.outerLink" [routerLink]="[linksubItem.link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{linksubItem.text}}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
    <lib-trial-indicator
        *ngIf="trial?.isTrial"
        [daysLeft]="trial.daysLeft"
        [generationsLeft]="trial.generationsLeft"
        [productName]="trial.productName">
    </lib-trial-indicator>

    <div *ngIf="!appView && !uiService.minHeader" class="mobile-nav-button">
        <div (click)="toggleMobileNav()" [class.open]="mobileNavOpened" id="nav-icon3">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>

    <div *ngIf="appView" class="header-company-name">
        <p *ngIf="!appSettings.impersonatedCompany">{{appSettings.loggedCompany.name}}</p>
        <p *ngIf="appSettings.impersonatedCompany" class="impersonated">
            <fa-icon title="Impersonating company" [icon]="impersonateIcon"></fa-icon>
            {{appSettings.impersonatedCompany.name}}
            <button title="Stop Impersonating" (click)="stopImpersonating()" class="close icon-button primary">
                <fa-icon [icon]="closeIcon"></fa-icon>
            </button>
        </p>
    </div>

    <nav class="main-nav navbar-nav1 user-nav"
        [class.demo-nav-parent]="demoAndQuote && !isLoggedIn"
        [class.right-aligned]="hideUserDropdown"
        *ngIf="!uiService.minHeader">
        <div class="login-sidebar" [class.open]="mobileUserNavOpened">
            <a class="login-dropdown" #loginMenuButton (click)="toggleUserMenu()">
                <fa-icon  class="user-head-icon" [icon]="emptyUserIcon"></fa-icon>
                <!-- <fa-icon class="arrow-icon" [icon]="faCaretDownIcon"></fa-icon> -->
            </a>
            <!-- <ng-sidebar-container>
                <ng-sidebar
                    #loginButtons
                    [(opened)]="opened"
                    [mode]="'over'"
                    [position]="'right'"
                    [sidebarClass]="sidebarClass"
                    [class.open-full]="openFull"
                    [closeOnClickOutside]="true"
                    (onClosed)="onSidebarClosed()"
                    [autoFocus]="false">

            </ng-sidebar>
            </ng-sidebar-container> -->
        </div>



        <ul class="userNav" #loginMenu [class.open]="mobileUserNavOpened">

            <!-- <li *ngIf="!isLoggedIn">
                <button class="primary no-bg">Free Trial</button>
            </li> -->
            <li class="animated-showing" *ngIf="uiService.showDemo">
                <button [routerLink]="[{ outlets: { sidePopup: ['demo','Platform']}}]" [queryParams]="{'returnUrl': router.url}" class="secondary no-bg">Demo</button>
            </li>
            <li class="animated-showing" *ngIf="uiService.showQuote">
                <button [routerLink]="[{ outlets: { sidePopup: ['quote','restgen','new']}}]"  class="secondary no-bg">Quote</button>
            </li>
            <li *ngIf="!isLoggedIn">
                <button (click)="Login()" class="text-button">Login</button>
            </li>
            <li *ngIf="!isLoggedIn">
                <button (click)="Register()" class="text-button">Sign Up</button>
            </li>
            <!-- <li *ngIf="!isLoggedIn">
                <button (click)="RegisterCompany()" class="text-button">Company</button>
            </li>
            <li *ngIf="!isLoggedIn">
                <button (click)="RegisterTrial()" class="text-button">Trial</button>
            </li> -->

        </ul>



        <div *ngIf="isLoggedIn" class="apps-dropdown" dropdown>
            <a class="apps-icon" dropdownToggle  title="Open Apps"  >
                <img src="/assets/images/bentoIcon.png" alt="Open Apps">
                <!-- <fa-icon [icon]="faCaretDownIcon"></fa-icon> -->
            </a>
            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                [class.right-aligned]="hideUserDropdown"
                role="menu" aria-labelledby="button-animated">
                <li class="heading">{{companyName}}</li>
                <li *ngFor="let listItem of appsList" role="menuitem">
                    <!-- <div *ngIf="listItem.licenseStatus === 'invalid'"
                        class="dropdown-item disabled-item">
                        <span class="prod-name">{{listItem.text}}</span>
                        <div>
                            <a role="button" class="text-button">Get Quote</a>
                        </div>
                    </div> -->
                    <a *ngIf="listItem.outerLink && !listItem.licenseInvalid"
                        [href]="listItem.link"
                        target="_blank"
                        [title]="listItem.altText"
                        [attr.aria-label]="listItem.altText"
                        class="dropdown-item" [innerHtml]="listItem.text">
                    </a>
                    <a *ngIf="!listItem.outerLink && !listItem.licenseInvalid"
                        [routerLink]="listItem.link"
                        [title]="listItem.altText"
                        [attr.aria-label]="listItem.altText"
                        class="dropdown-item" [innerHtml]="listItem.text">
                    </a>

                    <a *ngIf="listItem.outerLink && listItem.licenseInvalid"
                        [href]="listItem.invalidLicenseLink"
                        target="_blank"
                        [title]="listItem.invalidLicenseAltText"
                        [attr.aria-label]="listItem.invalidLicenseAltText"
                        class="dropdown-item" [innerHtml]="listItem.invalidLicenseText">
                    </a>
                    <a *ngIf="!listItem.outerLink && listItem.licenseInvalid"
                        [routerLink]="listItem.invalidLicenseLink"
                        [title]="listItem.invalidLicenseAltText"
                        [attr.aria-label]="listItem.invalidLicenseAltText"
                        class="dropdown-item" [innerHtml]="listItem.invalidLicenseText">
                    </a>
                </li>
            </ul>
        </div>

        <div *ngIf="ShowDropdown()">
            <lib-user-dropdown></lib-user-dropdown>
        </div>

    </nav>
</header>
