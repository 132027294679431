import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
// PIPES
import { FilterPipe } from './pipes/filter.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { appLinksPipe } from './common/header/appLinks.pipe';
import { pageLinksPipe } from './common/header/pageLinks.pipe';
import { subnavPipe } from './common/header/subnav.pipe';
import { NineGoldLibComponent } from './nine-gold-lib.component';
import { PaginationPipe } from './pipes/pagination.pipe';
import { VisibleColumnsPipe } from './pipes/visibleColumns.pipe';
// DIRECTIVES
import { FormFieldComponent } from './directives/form-field/form-field.component';
import { FileInputComponent } from './directives/file-input/file-input.component';
import { DndDirective } from './directives/file-input/dnd.directive';
import { InlineEditComponent } from './directives/inline-edit/inline-edit.component';
import { InlineFormFieldComponent } from './directives/inline-form-field/inline-form-field.component';
import { RadioGroupComponent } from './directives/radio-group/radio-group.component';
import { ScrollToTopComponent } from './directives/scroll-to-top/scroll-to-top.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { LoadingDirective } from './directives/loading.directive';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { StickyScrollDirective } from './directives/sticky-scroll.directive';
import { JsonFormatterComponent } from './directives/json-formater.component';
// COMMON
import { HeaderComponent } from './common/header/header.component';
import { NgLibHoverPopupDirective } from './common/header/ngLibHoverPopup.directive';
import { FooterComponent } from './common/footer/footer.component';
import { TrialIndicatorComponent } from './common/trial-indicator/trial-indicator.component';
import { UserInfoComponent } from './common/user/user-info/user-info.component';
import { UserDropdownComponent } from './common/user-dropdown/user-dropdown.component';
// LAYOUTS
import { AppComponent } from './layouts/app/app.component';
import { SiteHomeComponent } from './layouts/site-home/site-home.component';
import { SiteContentComponent } from './layouts/site-content/site-content.component';
import { AppconfigModule } from './services/appconfig/appconfig.module';
import { AccordionComponent } from './shared/accordion/accordion.component';
import { BoxContainerComponent } from './shared/box-container/box-container.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { NgDemoComponent } from './shared/forms/ng-demo/ng-demo.component';
// import { NgQuoteComponent } from './shared/forms/ng-quote/ng-quote.component';
import { NgQuoteNewComponent } from './shared/forms/ng-quote-new/ng-quote-new.component';
import { NgContactComponent } from './shared/forms/ng-contact/ng-contact.component';
import { NgItemsPerPageComponent } from './shared/listComponents/ng-items-per-page/ng-items-per-page.component';
import { SortSelectComponent } from './shared/listComponents/sort-select/sort-select.component';
import { NgAppliedFiltersComponent } from './shared/listComponents/ng-applied-filters/ng-applied-filters.component';
import { NgListComponent } from './shared/listComponents/ng-list/ng-list.component';
import { NgPaginationComponent } from './shared/listComponents/ng-pagination/ng-pagination.component';
import { NgNavigationComponent } from './shared/ng-navigation/ng-navigation.component';
import { NotificationModule } from './shared/notification/notification.module';
import { UserRoleIconComponent } from './shared/visual/user-role-icon/user-role-icon.component';
import { CounterComponent } from './shared/visual/counter/counter.component';
// BOOTSTRAP
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
// 3RD PARTY
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { SidebarModule } from 'ng-sidebar';
import { MonacoEditorModule, NgxMonacoEditorConfig  } from 'ngx-monaco-editor';
import { CountdownModule } from 'ngx-countdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SelectFormFieldComponent } from './directives/select-form-field/select-form-field.component';
import { ToggleSwitchComponent } from './directives/toggle-switch/toggle-switch.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SortColumnsPipe } from './pipes/sortColumns.pipe';
import { FileDownloadComponent } from './directives/file-download/file-download.component';
import { ClipboardCopyComponent } from './directives/clipboard-copy/clipboard-copy.component';
import { FormSummaryComponent } from './directives/form-summary/form-summary.component';
import { SimpleFilterPipe } from './pipes/simple-filter.pipe';
import { SupportRequestFormComponent } from './shared/forms/support-request-form/support-request-form.component';
import { MultiselectComponent } from './directives/multiselect/multiselect.component';
import { SearchPipe } from './directives/multiselect/search.pipe';
import { StatusBarModule } from './shared/status-bar/status-bar.module';



// export const options: Partial<IConfig> | (() => Partial<IConfig>);
const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: './assets', // configure base path for monaco editor default: './assets'
  defaultOptions: { scrollBeyondLastLine: false }, // pass default options to be used
  onMonacoLoad: () => {
    // console.log((<any>window).monaco);
  } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};


@NgModule({
  declarations: [
    NineGoldLibComponent,
// PIPES
    subnavPipe,
    pageLinksPipe,
    appLinksPipe,
    FilterPipe,
    SimpleFilterPipe,
    OrderByPipe,
    PaginationPipe,
    VisibleColumnsPipe,
    SortColumnsPipe,
    SearchPipe,
// DIRECTIVES
    FormFieldComponent,
    SelectFormFieldComponent,
    FileInputComponent,
    DndDirective,
    InlineEditComponent,
    InlineFormFieldComponent,
    RadioGroupComponent,
    ScrollToTopComponent,
    AutofocusDirective,
    LoadingDirective,
    ScrollSpyDirective,
    StickyScrollDirective,
    ToggleSwitchComponent,
    JsonFormatterComponent,
    FileDownloadComponent,
    ClipboardCopyComponent,
    FormSummaryComponent,
// COMMON
    HeaderComponent,
    NgLibHoverPopupDirective,
    FooterComponent,
    TrialIndicatorComponent,
    UserInfoComponent,
    UserDropdownComponent,
// LAYOUTS
    AppComponent,
    SiteHomeComponent,
    SiteContentComponent,
// SHARED
    AccordionComponent,
    BoxContainerComponent,
    ConfirmDialogComponent,
    NgDemoComponent,
    // NgQuoteComponent,
    NgQuoteNewComponent,
    NgContactComponent,
    NgItemsPerPageComponent,
    SortSelectComponent,
    NgAppliedFiltersComponent,
    NgListComponent,
    NgPaginationComponent,
    NgNavigationComponent,
    CounterComponent,
    UserRoleIconComponent,
    SupportRequestFormComponent,
    MultiselectComponent
  ],
  imports: [
// SYSTEM
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    DragDropModule,
// INTERNAL
    AppconfigModule,
    NotificationModule,
    StatusBarModule,
// BOOTSTRAP
    BsDropdownModule,
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
// 3RD PARTY
    NgSelectModule,
    NgScrollbarModule,
    FontAwesomeModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    SidebarModule.forRoot(),
    MonacoEditorModule.forRoot(monacoConfig),
    NgxIntlTelInputModule,
    CountdownModule,
  ],
  exports: [
    NineGoldLibComponent,
// PIPES
    OrderByPipe,
    FilterPipe,
    SortColumnsPipe,
    SearchPipe,
// DIRECTIVES
    FormFieldComponent,
    SelectFormFieldComponent,
    FileInputComponent,
    // DndDirective,
    InlineEditComponent,
    InlineFormFieldComponent,
    RadioGroupComponent,
    ScrollToTopComponent,
    LoadingDirective,
    ScrollSpyDirective,
    StickyScrollDirective,
    ToggleSwitchComponent,
    JsonFormatterComponent,
    FileDownloadComponent,
    ClipboardCopyComponent,
    FormSummaryComponent,
// COMMON
    HeaderComponent,
    FooterComponent,
    TrialIndicatorComponent,
// SHARED
    AccordionComponent,
    BoxContainerComponent,
    ConfirmDialogComponent,
    NgDemoComponent,
    // NgQuoteComponent,
    NgQuoteNewComponent,
    NgContactComponent,
    NgItemsPerPageComponent,
    SortSelectComponent,
    NgAppliedFiltersComponent,
    NgListComponent,
    NgPaginationComponent,
    NgNavigationComponent,
    CounterComponent,
    UserRoleIconComponent,
    // EXTERNAL
    TooltipModule,
    ButtonsModule,
    PopoverModule,
    MonacoEditorModule,
    BsDropdownModule,
    TypeaheadModule,
    CountdownModule,
    NgScrollbarModule,
    SupportRequestFormComponent,
    MultiselectComponent
],
  providers: [
    DecimalPipe,
    OrderByPipe,
    FilterPipe,
    SimpleFilterPipe
    // SortColumnsPipe
  ]
})
export class NineGoldLibModule {
  constructor (@Optional() @SkipSelf() parentModule: BrowserAnimationsModule) {
    if (parentModule) {
      // throw new Error(
      //   'Core is already loaded. Import it in the AppModule only');
    }
  }
}
