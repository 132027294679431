import { Component, OnInit, ViewEncapsulation, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgNavigation, MenuItemType, NgNavigationApps } from 'projects/nine-gold-lib/src/lib/shared/ng-navigation/ng-navigation';
import { faBars, faCaretDown, faCode, faExternalLinkAlt, faHome, faMasksTheater, faSignOutAlt, faTimes, faTools, faUserSecret} from '@fortawesome/free-solid-svg-icons';
import { subnavPipe } from './subnav.pipe';
import { pageLinksPipe } from './pageLinks.pipe';
import { appLinksPipe } from './appLinks.pipe';
import { UserService } from '../../services/user.service';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { FormResultMessageFormat } from '../../shared/notification/notification';
import { UserInterfaceService } from '../../services/user-interface.service';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { AppSettingsService } from '../../services/app-settings.service';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { ignoreElements } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ITrialStatus } from '../../models/user';
import { ConfirmDialogService } from 'projects/nine-gold-lib/src/lib/shared/confirm-dialog/confirm-dialog.service';
import { ConfirmDialog } from '../../shared/confirm-dialog/confirm-dialog';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: true } }]
})
export class HeaderComponent implements OnInit {
  faCaretDownIcon = faCaretDown;
  codeIcon = faCode;
  outerLinkIcon = faExternalLinkAlt;
  toolsIcon = faTools;
  emptyUserIcon = faUserCircle;
  barsIcon = faBars;
  houseIcon = faHome;

  closeIcon = faSignOutAlt;

  impersonateIcon = faMasksTheater;

  trial: ITrialStatus;

  mobileNavOpened = false;
  mobileUserNavOpened = false;

  userLoggedIn: boolean;
  productMenuOpen = false;
  products = {};
  listenerFn: () => void;
  listenerFn2: () => void;
  listenerFn3: () => void;
  hoverElement = null;
  @ViewChild('productmenu') productmenu: ElementRef;
  @ViewChild('loginMenu') loginMenu: ElementRef;
  @ViewChild('loginMenuButton') loginMenuButton: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  demoAndQuote: boolean;

  isLoggedIn = false; // new login property
  companyName: string = null;

  @Input() linksList: NgNavigation[];
  @Input() productsList: NgNavigation[];
  @Input() appsList: NgNavigationApps[];
  @Input() hideUserDropdown: boolean;
  @Input() appView: boolean;

  constructor(
    private userService: UserService,
    public router: Router,
    private authService: AuthService,
    private notifications: NotificationService,
    public uiService: UserInterfaceService,
    private renderer: Renderer2,
    public appSettings: AppSettingsService,
    private confirmService: ConfirmDialogService,
    private location: Location,
    private productService: ProductService
    ) {
      this.authService.loginChange.subscribe(loggedIn => {
        this.isLoggedIn = loggedIn;
      });
      router.events.subscribe((val) => {
        if(val instanceof NavigationStart) {
          if(this.mobileNavOpened) {
            this.toggleMobileNav();
          }
        }
      });
    }

  ngOnInit(): void {
    this.productsList.forEach(el => {
      this.products[el.name] = el;
    });
    this.userLoggedIn = this.userService.userLoggedIn();
    this.demoAndQuote = this.appSettings.demoAndQoute;

    if (!this.location.path().startsWith('/signin-callback') && !this.location.path().startsWith('/signout-callback') && !this.location.path().startsWith('/signout-callback')) {
      this.authService.isLoggedIn().then(loggedIn => {
        this.isLoggedIn = loggedIn;
        if (loggedIn) {
          this.notifications.info('Sign in successful', `User is logged in successfully.`, FormResultMessageFormat.popup, 3000);
        }
        if(!loggedIn) {
          this.authService.checkSessionStatus()
          .then(a => {
          });
        }
      });
    }
    this.userService.userUpdated.subscribe(user => {
      this.companyName = this.userService.getCompanyName();
      this.trial = this.userService.getTrialStatus();
      if(this.trial?.isTrial) {
        document.body.classList.add("trial");
      } else {
        document.body.classList.remove("trial");
      }
    })


  }

  stopImpersonating(): void {
    this.confirmService.confirm(this.uiService.getConfirmMessage('stopImpersonating',[this.appSettings.impersonatedCompany.name])).subscribe(answer => {
      if(answer) {
        this.appSettings.impersonateCompany(null);
        this.productService.setLicensesFromApi();
        setTimeout(() => {
          this.router.navigate(['/user/super-admin']);
        }, 200);
      }
    });
  }

  toggleUserMenu() {
    this.mobileUserNavOpened = !this.mobileUserNavOpened;
    if(this.mobileUserNavOpened) {
      this.listenerFn2 = this.renderer.listen('window', 'click',(e:Event)=>{
        if(e.target !== this.loginMenu.nativeElement && e.target !== this.loginMenuButton.nativeElement
          && !this.loginMenuButton.nativeElement.contains(e.target)){
          this.mobileUserNavOpened = false;
          this.listenerFn2();
        }
      });
    } else {
      this.listenerFn2();
    }
  }

  hoverToggle(event: any) {
    var mouseState = event.target.getAttribute('mouse');
    event.target.setAttribute('mouse','true');
    if(!event.target.className.includes('open')) {
      event.target.getElementsByTagName('a')[0].click();
      var el = event.target;
      this.listenerFn3 = this.renderer.listen(event.target, 'mouseleave',(e:Event)=>{
        el.setAttribute('mouse','false');
        setTimeout(() => {
          if(el.getAttribute('mouse') !== 'true') {
            this.listenerFn3();
            el.getElementsByTagName('a')[0].click();
          }
        },1000);
      });
    }
  }

  protected filterNotHidden(list: NgNavigation[]):NgNavigation[] {
    return list.filter(el=>!el.hide);
  }

  mouseLeave(event: any) {
    let el = event.target;
    setTimeout(() => {
      el.onmouseout = () => {
        el.getElementsByTagName('a')[0].click();
      }
    }, 1000)
  }

  toggleMobileNav() {
    this.mobileNavOpened = !this.mobileNavOpened;
    if (this.mobileNavOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }

  toggleProductMenu():void {
    this.productMenuOpen = !this.productMenuOpen;
    if(this.productMenuOpen) {
      this.listenerFn = this.renderer.listen('window', 'click',(e:Event)=>{
        /**
         * Only run when toggleButton is not clicked
         * If we don't check this, all clicks (even on the toggle button) gets into this
         * section which in the result we might never see the menu open!
         * And the menu itself is checked here, and it's where we check just outside of
         * the menu and button the condition abbove must close the menu
         */
          if(e.target !== this.productmenu.nativeElement && e.target !== this.toggleButton.nativeElement){
              this.productMenuOpen = false;
              this.listenerFn();
          }
      });
    } else {
      this.listenerFn();
    }
  }

  UserLogged() {
    return this.userService.userLoggedIn();
  }

  ShowDropdown():boolean {
    return this.isLoggedIn && !this.hideUserDropdown;
  }
  ShowLinkPerView(link: NgNavigation): boolean {
    let showLink = false;
    if(this.appView) {
      showLink = link.appLink || false;
    } else {
      showLink = link.pageLink || false;
    }
    return showLink;
  }

  Login() {
    this.authService.login(this.router.url);
  }

  Register() {
    this.authService.getRegistrationLink().then(link => window.location.href = link);
  }
  RegisterCompany() {
    this.authService.getRegistrationLink(false, "COMPANY NAME").then(link => window.location.href = link);
  }
  RegisterTrial() {
    this.authService.getRegistrationLink(true, null).then(link => window.location.href = link);
  }

  NewLogout() {
    this.authService.new_logout();
  }

  Logout() {
    this.authService.logout();
    this.userLoggedIn = this.userService.userLoggedIn();
    this.uiService.setDemoAndQuote(null);
    this.notifications.success('Logout Successful', 'User was successfully logged out.', FormResultMessageFormat.popup, null, null);
  }

  isLinkDropDown(link: NgNavigation): boolean {
    return link.itemType === MenuItemType.dropdown;
  }

}
