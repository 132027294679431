import { Injectable } from '@angular/core';
import { ICompany } from '../models/company';
import { ApiProductExtended, ApiProductStatus } from '../models/product';
import { ColumnFilter } from '../shared/listComponents/ng-list/ng-list-data';
import { LicenseStatus, MenuItemType, NgNavigation, NgNavigationApps } from '../shared/ng-navigation/ng-navigation';
import { EnvData } from './appconfig/env-data';

@Injectable()
export class AppSettingsService {
  /** Setting template for the application
   * This will have all function and variables needed for applications
   * If function and variable value is same for all applications it will be set here
   * if not, it will be set in child service inside each app
  */

  /**
   * API URL is coming from env variable.
  */

  baseSetting: EnvData = {
    production: false,
    baseUrl: '',
    stsData: {
      url: '',
      clientName:'',
      scope: ''
    },
    projectUrls: {
      platform: '',
      generator: '',
      tools: ''
    },
    apiUrl: '',
    supportUrl: ''
  };

  protected productCode: string;

  public apiHardLoadTimeout = 120000;  // 2 minutes for reload list from API if ui sorting is set

  companyId = 'mine';

  public loggedCompany: ICompany = {
    id: 'mine',
    name: ''
  };

  public impersonatedCompany: ICompany = null;


  /**
   * list of APIs will be same cross apps
   * maybe I should add list of params
  */
  apiList = [];

  /**
   * URLs for each app coming from env variable
  */

  // navigations:
  topNavLinks: NgNavigation[];
  productLinks: NgNavigation[];
  appsList: NgNavigationApps[];
  footerLinks: NgNavigation[];
  userNavLinks: NgNavigation[];
  infoNavLinks: NgNavigation[];
  solutionsLinks: NgNavigation[];
  resourcesLinks: NgNavigation[];

  // Download page data
  donwnloadFilters: ColumnFilter;
  defaulDownloadParams = {
    numberOfPages: 5,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "generated",
      order: "d"
    }
  }

  public demoAndQoute = false;

  constructor() {
    this.setApis();
  }

  public logCompany(company: ICompany): void {
    this.loggedCompany = company;
  }

  public impersonateCompany(company: ICompany): void {
    console.log(company)
    this.impersonatedCompany = company;
  }

  private setApis(): void {
    this.apiList = [
      // SYSTEM APIs
      {
        name: 'systemVersion',
        value: '/v1/system/version',
      },
      {
        name: 'systemHealth',
        value: '/v1/system/health',
      },
      // PRODUCTS API
      {
        name: 'products',
        value: `/v1/products`,
      },
      {
        name: 'productCategories',
        value: `/v1/productcategories`
      },
      {
        name: 'companyProducts',
        value: `/v1/companies/#param1/products`
      },
      // USER APIs
      {
        name: 'currentUser',
        value: '/v1/user'
      },
      {
        name: 'currentUserDetails',
        value: '/v1/user/download'
      },
      {
        name: 'currentCompanyUserDetails',
        value: `/v1/company/users/#param1`,
        value2: '/v1/companies/#companyId/users/#param1'
      },
      {
        name: 'userDetails',
        value: `/v1/companies/#param1/users/#param2`
      },
      // USER DATA
      {
        name: 'userData',
        value: `/v1/companies/#param1/users/#param2/data`,
      },
      {
        name: 'currentCompanyUserData',
        value: `/v1/company/users/#param1/data`,
        value2: `/v1/companies/#companyId/users/#param1/data`,
      },
      // USERS LIST
      {
        name: 'usersList',
        value: `/v1/companies/#param1/users/members`
      },
      {
        name: 'currentCompanyUsersList',
        value: `/v1/company/users`,
        value2: `/v1/companies/#companyId/users`,
      },
      // COMPANIES LIST
      {
        name: 'companiesList',
        value: `/v1/companies`
      },
      // COMPANY DETAILS
      {
        name: 'companyDetails',
        value: `/v1/companies/#param1`
      },
      {
        name: 'currentCompanyDetails',
        value: `/v1/company`,
        value2: `/v1/companies/#companyId`
      },
      // IMPERSONATE COMPANY
      {
        name: 'impersonateCompany',
        value: `/v1/session/company`
      },
      // ACTIONS LIST
      {
        name: 'actionsList',
        value: `/v1/companies/#param1/actions`,
      },
      {
        name: 'currentCompanyActionsList',
        value: `/v1/company/actions`,
        value2: `/v1/companies/#companyId/actions`,
      },
      // ACTION DETAILS
      {
        name: 'actionDetails',
        value: `/v1/companies/#param1/actions/#param2`,
      },
      {
        name: 'currentCompanyActionDetails',
        value: `/v1/company/actions/#param1`,
        value2: `/v1/companies/#companyId/actions/#param1`,
      },
      // INVITES LIST
      {
        name: 'invitesList',
        value: `/v1/companies/#param1/invites`
      },
      {
        name: 'currentCompanyInvitesList',
        value: `/v1/company/invites`,
        value2: `/v1/companies/#companyId/invites`
      },
      // INVITE DATAILS
      {
        name: 'inviteDetails',
        value: `/v1/companies/#param1/invites/#param2`
      },
      {
        name: 'currentCompanyInviteDetails',
        value: '/v1/company/invites/#param1',
        value2: `/v1/companies/#companyId/invites/#param2`
      },
      // USES LIST
      {
        name: 'usesList',
        value: `/v1/companies/#param1/uses`
      },
      {
        name: 'usesByProductCategory',
        value: `/v1/company/uses?productCategoriesIds=#param1&allowEmptyResponse=true`
      },
      {
        name: 'usesByProduct',
        value: `/v1/company/uses?productId=#param1&allowEmptyResponse=true`
      },
      {
        name: 'currentCompanyUsesList',
        value: `/v1/company/uses`,
        value2: `/v1/companies/#companyId/uses`
      },
      // Application Use
      {
        name: 'appUse',
        value: `/v1/companies/#param1/uses/#param2`
      },
      {
        name: 'licensesList',
        value: `/v1/companies/#param1/licenses`
      },
      {
        name: 'license',
        value: `/v1/companies/#param1/licenses/#param2`
      },
      {
        name: 'currentCompanyLicensesList',
        value: `/v1/company/licenses`,
        value2: `/v1/companies/#companyId/licenses`
      },
      // INVITATION ACCEPT ACTIONS
      {
        name: 'invitationAccept',
        value: '/v1/accept'
      },
      {
        name: 'invitationDetails',
        value: '/v1/accept/#param1'
      },
      // SUPPORT FORMS: contact, quote, demo
      {
        name: 'message',
        value: '/v1/system/message'
      },
      {
        name: 'faq',
        value: '/v1/system/faq'
      },
      // GENERATOR APIs
      {
        name:'parseStatus',
        value: '/v1/companies/#param1/uses/restgen/parse-status/#param2'
      },
      {
        name: 'restgenSpecification',
        value: '/v1/companies/#param1/uses/restgen-specifications/#param2/#param3'
      },
      {
        name: 'parseHistory',
        value: '/v1/companies/#param1/uses/restgen/parse-history'
      },
      // OLDER APIs to review
      {
        name: 'generator',
        value: '/ninegold/generate/v1'
      },
      {
        name: 'downloadsList',
        value: '/api/downloads'
      },
      {
        name: 'jsonConverter',
        value: '/ninegold/tools/generate/jsonExample/v1'
      }
    ]
  }

  public getProductCode():string {
    return this.productCode;
  }

  public setNavigations(platformUrl: string, generatorUrl: string, toolsUrl: string): void {

    this.topNavLinks = [
      {
        name: 'generatorPricing',
        link: generatorUrl + '/pricing',
        text: 'Pricing',
        outerLink: false,
        newTab: false,
        pageLink: true,
        appLink: false,
        altText: 'Generator Pricing Details',
        onlyFor: generatorUrl
      },
      {
        name: 'generator-app',
        link: '',
        text: 'Application',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'Application',
        itemType: MenuItemType.dropdown,
        onlyFor: generatorUrl
      },
      {
        name: 'generator',
        link: '/app/generator',
        text: 'Generator',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'Generator application',
        itemType: MenuItemType.link,
        parent: 'generator-app',
        onlyFor: generatorUrl
      },
      {
        name: 'generator-downloads',
        link: '/app/downloads',
        text: 'Downloads',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'Downloads page',
        itemType: MenuItemType.link,
        parent: 'generator-app',
        onlyFor: generatorUrl
      },
      {
        name: 'generator-recent-uploads',
        link: '/app/recent-uploads',
        text: 'Recent Uploads',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'Recent Uploads List page',
        itemType: MenuItemType.link,
        parent: 'generator-app',
        onlyFor: generatorUrl
      },
      {
        name: 'tools-app-dropdown',
        link: '',
        text: 'Application',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'Application',
        itemType: MenuItemType.dropdown,
        onlyFor: toolsUrl
      },
      {
        name: 'jsonSchemaToRaml',
        link: '/app/json-schema-to-raml',
        text: 'JSON Schema &rArr; RAML Converter',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'JSON Schema to RAML Converter Application',
        itemType: MenuItemType.link,
        parent: 'tools-app-dropdown',
        onlyFor: toolsUrl
      },
      {
        name: 'ramlExampleToJson',
        link: '/app/raml-example-to-json',
        text: 'RAML Example &hArr; JSON Converter',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'RAML Example to JSON or JSON to RAML Example Converter Application',
        itemType: MenuItemType.link,
        parent: 'tools-app-dropdown',
        onlyFor: toolsUrl
      },
      {
        name: 'ramlOasConverter',
        link: '/app/raml-oas-converter',
        text: 'RAML &hArr; OAS Converter',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'RAML to OAS or OAS to RAML Converter Application',
        itemType: MenuItemType.link,
        parent: 'tools-app-dropdown',
        onlyFor: toolsUrl
      },
      {
        name: 'postmanGenerator',
        link: '/app/postman-generator',
        text: 'Postman Generator',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'Postman v2 Generator Application',
        itemType: MenuItemType.link,
        parent: 'tools-app-dropdown',
        onlyFor: toolsUrl
      },
      {
        name: 'generator-downloads',
        link: '/app/downloads',
        text: 'Downloads',
        outerLink: false,
        newTab: false,
        pageLink: true,
        altText: 'Downloads page',
        itemType: MenuItemType.link,
        parent: 'tools-app-dropdown',
        onlyFor: toolsUrl
      },
      {
        name: 'company',
        link: '',
        text: 'Company',
        outerLink: false,
        newTab: false,
        altText: 'Smart Code pages',
        itemType: MenuItemType.dropdown,
        pageLink: true
      },
      {
        name: 'platform',
        link: platformUrl + '/',
        text: 'Platform Home',
        outerLink: true,
        newTab: true,
        altText: 'Platform',
        pageLink: true,
        parent: 'company',
        itemType: MenuItemType.link,
        removeOnSelf: true
      },
      {
        name: 'about',
        link: platformUrl + '/about',
        text: 'About',
        outerLink: true,
        newTab: true,
        altText: 'About Smart Code',
        pageLink: true,
        parent: 'company',
        itemType: MenuItemType.link
      },
      {
        name: 'contact',
        link: platformUrl + '/contact',
        text: 'Contact',
        outerLink: true,
        newTab: true,
        altText: 'Contact Us',
        pageLink: true,
        appLink: true,
        parent: 'company',
        itemType: MenuItemType.link
      },
      // {
      //   name: 'resources',
      //   link: '',
      //   text: 'Resources',
      //   outerLink: false,
      //   newTab: false,
      //   altText: 'Smart Code Resources',
      //   itemType: MenuItemType.dropdown,
      //   pageLink: true
      // },
      // {
      //   name: 'whitepaper',
      //   link: generatorUrl + '/whitepaper-download',
      //   text: 'Whitepaper',
      //   outerLink: true,
      //   newTab: true,
      //   altText: 'Whitepaper',
      //   pageLink: true,
      //   parent: 'resources',
      //   itemType: MenuItemType.link,
      //   removeOnSelf: false
      // },
      // {
      //   name: 'faq',
      //   link: platformUrl + '/faq',
      //   text: 'FAQ',
      //   outerLink: true,
      //   newTab: true,
      //   altText: 'Platform FAQs',
      //   pageLink: true,
      //   parent: 'resources',
      //   itemType: MenuItemType.link,
      //   removeOnSelf: false
      // },
    ];

    this.resourcesLinks = [
      {
        name: 'whitepaper',
        link: generatorUrl + '/whitepaper-download',
        text: 'Whitepaper',
        outerLink: true,
        newTab: true,
        altText: 'Whitepaper',
        pageLink: true,
        parent: 'resources',
        itemType: MenuItemType.link,
        removeOnSelf: false
      },
      {
        name: 'faq',
        link: platformUrl + '/faq',
        text: 'FAQ',
        outerLink: true,
        newTab: true,
        altText: 'Platform FAQs',
        pageLink: true,
        parent: 'resources',
        itemType: MenuItemType.link,
        removeOnSelf: false
      }
    ];

    this.solutionsLinks = [
      {
        name: 'our-strategy',
        link: platformUrl + '/our-strategy/',
        text: 'Our Strategy',
        outerLink: true,
        newTab: true,
        altText: 'Our Strategy',
        pageLink: true,
        parent: 'solutions'
      },
      {
        name: 'calculating-the-cost',
        link: platformUrl + '/calculating-the-cost/',
        text: 'Calculating the Cost',
        outerLink: true,
        newTab: true,
        altText: 'Calculating the Cost',
        pageLink: true,
        parent: 'solutions'
      },
      {
        name: 'migration-risk-assessment',
        link: platformUrl + '/migration-risk-assessment/',
        text: 'Migration Risk Assessment',
        outerLink: true,
        newTab: true,
        altText: 'Migration Risk Assessment',
        pageLink: true,
        parent: 'solutions'
      }
    ];

    this.productLinks = [
      {
        name: 'generator',
        link: generatorUrl + '/',
        text: 'REST Generator',
        outerLink: true,
        newTab: true,
        altText: 'Smart Projects / REST Generator',
        parent: 'products'
      },
      {
        name: 'tools',
        link: toolsUrl + '/',
        text: 'UTIL Generator',
        outerLink: true,
        newTab: true,
        altText: 'Smart Utilities / UTIL Generator',
        parent: 'products'
      },
      {
        name: 'pricing',
        link: generatorUrl + '/pricing/',
        text: 'Subscription',
        outerLink: true,
        newTab: true,
        altText: 'Subscription',
        parent: 'products'
      }
    ];

    this.appsList = [
      {
        name: 'restgen',
        link: generatorUrl + '/app/generator',
        text: 'REST Generator',
        outerLink: true,
        newTab: true,
        altText: 'REST Generator Application',
        parent: 'products',
        licenseStatus: LicenseStatus.valid,
        quoteButtonLink: '',
        quoteButtonText: 'Get Quote',
        renewButtonLink: '',
        renewButtonText: 'Renew',
        invalidLicenseLink: generatorUrl + '/pricing',
        invalidLicenseText: 'REST Generator <span>see pricing</span>',
        invalidLicenseAltText: 'No valid license for rest generator - see product pricing page.'
      },
      {
        name: 'jsonSchemaToRaml',
        link: toolsUrl + '/app/json-schema-to-raml',
        text: 'JSON Schema &rArr; RAML',
        outerLink: true,
        newTab: true,
        altText: 'JSON Schema to RAML Converter Application',
        parent: 'products',
        licenseStatus: LicenseStatus.free
      },
      {
        name: 'ramlExampleToJson',
        link: toolsUrl + '/app/raml-example-to-json',
        text: 'RAML Example &hArr; JSON',
        outerLink: true,
        newTab: true,
        altText: 'RAML Example to JSON or JSON to RAML Example Converter Application',
        parent: 'products',
        licenseStatus: LicenseStatus.free
      },
      {
        name: 'ramlOasConverter',
        link: toolsUrl + '/app/raml-oas-converter',
        text: 'RAML &hArr; OAS Converter',
        outerLink: true,
        newTab: true,
        altText: 'RAML to OAS or OAS to RAML Converter Application',
        parent: 'products',
        licenseStatus: LicenseStatus.free
      },
      {
        name: 'postmanGenerator',
        link: toolsUrl + '/app/postman-generator',
        text: 'Postman Generator',
        outerLink: true,
        newTab: true,
        altText: 'Postman v2 Generator Application',
        parent: 'products',
        licenseStatus: LicenseStatus.free
      }
    ];


    this.footerLinks = [
      {
        name: 'generator',
        link: generatorUrl + '/',
        text: 'REST Generator',
        outerLink: true,
        newTab: true,
        altText: 'REST Generator',
        parent: 'products'
      },
      // {
      //   name: 'pricing',
      //   link: generatorUrl + '/pricing/',
      //   text: 'Generator Pricing',
      //   outerLink: true,
      //   newTab: true,
      //   altText: 'REST Generator pricing details',
      //   parent: 'products'
      // },
      {
        name: 'tools',
        link: toolsUrl + '/',
        text: 'UTIL Generator',
        outerLink: true,
        newTab: true,
        altText: 'Smart Code Utilities',
        parent: 'products'
      },
      {
        name: 'platform',
        link: platformUrl + '/',
        text: 'Platform Home',
        outerLink: true,
        newTab: true,
        altText: 'Platform',
        pageLink: true,
        parent: 'company',
        // removeOnSelf: true
      },
      {
        name: 'about',
        link: platformUrl + '/about/',
        text: 'About',
        outerLink: true,
        newTab: true,
        altText: 'About Smart Code',
        pageLink: true,
        parent: 'company'
      },
      {
        name: 'our-strategy',
        link: platformUrl + '/our-strategy/',
        text: 'Our Strategy',
        outerLink: true,
        newTab: true,
        altText: 'Our Strategy',
        pageLink: true,
        parent: 'company'
      },
      {
        name: 'contact',
        link: platformUrl + '/contact/',
        text: 'Contact',
        outerLink: true,
        newTab: true,
        altText: 'Contact Us',
        pageLink: true,
        appLink: true,
        parent: 'company'
      },
      {
        name: 'whitepaper',
        link: generatorUrl + '/whitepaper-download',
        text: 'Avoiding Technial Debt',
        outerLink: true,
        newTab: true,
        altText: 'White Paper',
        pageLink: true,
        parent: 'resources'
      },
      {
        name: 'faq',
        link: platformUrl + '/faq',
        text: 'FAQ',
        outerLink: true,
        newTab: true,
        altText: 'Frequently Asked Questions',
        parent: 'resources'
      },
      {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/diamond-edge-it-consulting-inc/',
        text: 'LinkedIn',
        outerLink: true,
        newTab: true,
        altText: 'Diamond Edge IT LinkedIn',
        parent: 'social'
      },
      {
        name: 'youtube',
        link: 'https://www.youtube.com/channel/UCKP8mV_SUWlvW3E4S9x5cGQ',
        text: 'YouTube',
        outerLink: true,
        newTab: true,
        altText: 'Diamond Edge IT YouTube channel',
        parent: 'social'
      },
      {
        name: 'privacy',
        link: platformUrl + '/privacy/',
        text: 'Privacy Statement',
        outerLink: true,
        newTab: true,
        altText: 'Privacy Statement',
        parent: 'legal'
      },
      {
        name: 'terms',
        link: platformUrl + '/terms/',
        text: 'Terms of Service',
        outerLink: true,
        newTab: true,
        altText: 'Terms of Service',
        parent: 'legal'
      },
      {
        name: 'ccpa',
        link: platformUrl + '/california-consumer-privacy-statement/',
        text: 'CCPA Notice',
        outerLink: true,
        newTab: true,
        altText: "California Consumer Privacy Statement",
        parent: 'legal'
      }
    ];

    this.userNavLinks = [
      {
        name: 'user-info',
        link: platformUrl + '/user/info',
        text: 'User Profile',
        outerLink: true,
        newTab: true,
        altText: 'View and Edit User Information'
      },
      {
        name: 'user-license',
        link: platformUrl + '/user/license',
        text: 'Licenses',
        outerLink: true,
        newTab: true,
        altText: 'View and Edit Account Licenses',
        permissionRequired: 'canViewLicense'
      },
      {
        name: 'user-subusers',
        link: platformUrl + '/user/subusers',
        text: 'User Management',
        outerLink: true,
        newTab: true,
        altText: 'Manage Users and Ivitations',
        permissionRequired: 'canViewLicense'
      },
      // {
      //   name: 'actions',
      //   link: platformUrl + '/user/actions',
      //   text: 'Actions',
      //   outerLink: true,
      //   newTab: true,
      //   altText: 'Actions List',
      //   permissionRequired: 'canViewActions'
      // },
      {
        name: 'uses',
        link: platformUrl + '/user/downloads',
        text: 'Downloads',
        outerLink: true,
        newTab: true,
        altText: 'Downloads of products',
        customClass: 'downloads-icon'
      },
      {
        name: 'user-security',
        link: platformUrl + '/user/security',
        text: 'Security',
        outerLink: true,
        newTab: true,
        altText: 'Manage security'
      },
      {
        name: 'global-admin',
        link: platformUrl + '/user/super-admin',
        text: 'Global Admin',
        outerLink: true,
        newTab: true,
        altText: 'Manage Companies',
        permissionRequired: 'canListCompanies'
      }
    ];

    this.infoNavLinks = [
      {
        name: 'terms-of-service',
        link: platformUrl + '/terms/',
        text: 'Terms of Service',
        outerLink: true,
        newTab: true,
        altText: 'View Our Terms of Service'
      },
      {
        name: 'privacy-statement',
        link: platformUrl + '/privacy',
        text: 'Privacy Statement',
        outerLink: true,
        newTab: true,
        altText: 'Diamond Edge IT Consulting Privacy Statement'
      },
      {
        name: 'ccpa-notice',
        link: platformUrl + '/california-consumer-privacy-statement',
        text: 'CCPA Notice',
        outerLink: true,
        newTab: true,
        altText: 'Diamond Edge IT Consulting Privacy Notice for California Residents'
      }
    ];
  }

  public setAppsStatus(products: ApiProductExtended[]): void {
    products.forEach(p => {
      let app = this.appsList.find(a => a.name === p.id);
      if(app) {
        if(!p.hasLicense) {
          app.canLink = false;
          app.canQuote = true;
          app.canRenew = false;
          app.licenseStatus = LicenseStatus.invalid;
        } else if(p.status === ApiProductStatus.free) {
          app.licenseStatus = LicenseStatus.free;
          app.canLink = true;
        } else if(p.status === ApiProductStatus.trial) {
          app.licenseStatus = LicenseStatus.trial;
        } else {

        }
        // app.licenseStatus = p.hasLicense;
        // TODO: add params: canQUote, canRenew, status (expired, trial, free), canLink
        // TODO: add canTrial property (maybe to products list)
        // TODO: if free, show free (or not) and have active link
        // TODO: if trial, show trial to license and get quote button (canQuote = true), but link active
        // TODO: if trial expired, canLink = false, status trial expired, get quote button
        // TODO: if valid (project or enterprise), canLink true, no status
        // TODO: if expired (project or enterprise) status expired, link not active, Renew quote button
      }
    });
  }

  // TODO quote form should show on every project (maybe the site should be in library and just have links in every project using quote page componentn from library)

  public getApi(name: string, param1?: string, param2?:string,param3?:string): string {
    let apiObject = this.apiList.find(api => api.name === name);
    let value: string = '';
    if(apiObject) {
      if(this.impersonatedCompany && apiObject.value2) {
        value = apiObject.value2;
        value = value.replace(/#companyId/gi,this.impersonatedCompany.id);
      } else {
        value = apiObject.value;
      }
      if(param1) {
        value = value.replace(/#param1/gi, param1);
      }
      if(param2) {
        value = value.replace(/#param2/gi, param2);
      }
      if(param3) {
        value = value.replace(/#param3/gi, param3);
      }
    }

    return this.baseSetting.apiUrl + value;
  }

  public getTopNav(): NgNavigation[] {
    return this.topNavLinks;
  }

  public getFooterNav(): NgNavigation[] {
    return this.footerLinks;
  }

  public getUserNav(): NgNavigation[] {
    return this.userNavLinks;
  }

  public getProductLinks(): NgNavigation[] {
    return this.productLinks;
  }

  public getSolutionsLinks(): NgNavigation[] {
    return this.solutionsLinks;
  }

  public getResourcesLinks(): NgNavigation[] {
    return this.resourcesLinks;
  }

  public getAppLinks(): NgNavigationApps[] {
    return this.appsList;
  }

  public getInfoLinks(): NgNavigation[] {
    return this.infoNavLinks;
  }

  public getDownloadFilters(): ColumnFilter {
    return this.donwnloadFilters;
  }

  public getDefaulDownloadParams(): any {
    return this.defaulDownloadParams;
  }

  public RemoveLinks(list: NgNavigation[], baseUrl: string): NgNavigation[] {
    return list.filter(el => (!el.onlyFor || el.onlyFor === baseUrl));
  }

  public updateAppLinkStatus(name: string, invalid: boolean): void {
    let item = this.appsList.find(l => l.name === name);
    let index = this.appsList.indexOf(item);
    if(index > -1) {
      this.appsList[index].licenseInvalid = invalid;
    }
  }
  public updateAppLink(name: string, link: string, text: string): void {
    let appLink = this.appsList.find(l => l.name === name);
    if(appLink) {
      appLink.text = text;
      appLink.link = link;
    }
  }

  UpdateLocalLinks(list: NgNavigation[] | NgNavigationApps[], baseUrl: string): void {
    list.filter(el => el.link.startsWith(baseUrl)).forEach(el => {
      el.link = el.link.replace(baseUrl,"");
      if(el['invalidLicenseLink']) {
        el['invalidLicenseLink'] = el['invalidLicenseLink'].replace(baseUrl,"");
      }
      el.outerLink = false;
      el.newTab = false;
      if(el.removeOnSelf) el.hide = true;
    });
  }
}
